import { setToken } from "util/token";
import { Account, FormFields, LoginResponse, Signup } from "./types";
import useStore from "./useStore";
import getState from "./state";
import { ExternalSearchQueryParams } from "config/business";
import { getSearchParam } from "util/route";

export const accountFactory = ({
    iban = getSearchParam<string>({
        searchQueryName: ExternalSearchQueryParams.Iban,
    }),
    ...account
} = {}) => ({
    ...account,
    iban,
}) as Account;

export const setTokenFromResponse = ({ data }: LoginResponse) => {
    setToken(
        data.access_token,
        data.token_type,
        data.expires_in,
    );
};

export const setTokenFromSearchQueryParams = () => {
    const accessToken = getSearchParam<string>({
        searchQueryName: ExternalSearchQueryParams.AccessToken,
    });
    const tokenType = getSearchParam<string>({
        searchQueryName: ExternalSearchQueryParams.TokenType,
    });
    const expiresIn = getSearchParam({
        searchQueryName: ExternalSearchQueryParams.ExpiresIn,
    });

    if (!accessToken || !tokenType || !expiresIn) {
        return;
    }

    setToken(
        accessToken,
        tokenType,
        Number(expiresIn)
    );

    return accessToken;
};

export const resetStore = () => {
    useStore.setState({
        ...getState()
    });
};

export const registerAccountRequestAdapter = <TRequestData extends Partial<Signup>>(data: TRequestData) => ({
    first_name: data[FormFields.FirstName],
    last_name: data[FormFields.LastName],
    email: data[FormFields.Email],
    password: data[FormFields.Password],
    phone_number: data[FormFields.Phone]
});
