import { selectSubmitStep } from "feature/send-money/selectors";
import { SendMoneyStep, StepRegistry } from "feature/send-money/types";
import useStore from "feature/send-money/useStore";
import { QUERY_PARAMETER } from "feature/transaction/consts";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useStepper } from "ui/layouts/Stepper";

export default function useSendMoney() {
    const submitStep = useStore(selectSubmitStep);
    const next = useStepper();

    const param = useParams();

    const [isTopupSuccessful, setTopupSuccessful] = useState(false);

    const step = param[QUERY_PARAMETER] as SendMoneyStep;

    const onSubmit = useCallback(async (formData: StepRegistry[SendMoneyStep]) => {
        const { redirectUrl } = await submitStep(formData);

        if (step === SendMoneyStep.Second) {
            window.location.href = redirectUrl;
            setTopupSuccessful(true);
            return;
        }

        next();
    }, [submitStep, next, step]);

    return {
        onSubmit,
        isTopupSuccessful
    };
};
