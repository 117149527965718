import { ID } from "interface/entity";
import route from "util/route";

export const QUERY_PARAMETER = 'step';

export const STATUS_QUERY_PARAMETER = 'topUpId';

export const enum RouteTypes {
    Topups = 'topups'
};

export const TransactionApiRoutes = {
    Post: route(RouteTypes.Topups),
    Get: (topupUuid: string) => route('topUps', topupUuid),
    GetUserTopUp: (userId: ID, topupUuid: string) =>
        route('users', `${userId}`, 'topUps', topupUuid),
    GetUserTopUps: (userId: ID) =>
        route('users', `${userId}`, 'topUps')
};
