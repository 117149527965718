import Cookie from 'js-cookie';
import { addSeconds, isAfter } from 'date-fns';
import {
    COOKIE_DEFAULT_ATTRIBUTES,
    REFRESH_TOKEN_PATH,
    REFRESH_TOKEN_THRESHOLD_DIVIDER,
    TOKEN_COOKIE_NAME
} from 'config/auth';
import { capitalizeFirstLetter, isUnauthorized } from './support';
import { API_URL } from 'config/general';
import { headers } from 'config/api';
import route from './route';

const refreshTokenRequestRefSymbol = Symbol('refreshTokenRequestRefSymbol');

export const setToken = (
    accessToken: string,
    tokenType: string,
    expiresIn: number
) => {
    const token = serializeToken(
        accessToken,
        tokenType,
        getTokenRefreshThreshold(expiresIn)
    );

    Cookie.set(TOKEN_COOKIE_NAME, token, {
        ...COOKIE_DEFAULT_ATTRIBUTES,
        expires: addSeconds(new Date(), expiresIn)
    });
};

export const getToken = () => getTokenSegment(0);

export const removeToken = () => Cookie.remove(TOKEN_COOKIE_NAME);

export const getTokenType = () => capitalizeFirstLetter(
    getTokenSegment(1) ?? 'bearer'
);

export const getAuthorizationHeader = () => ({
    Authorization: [
        getTokenType(),
        getToken()
    ].join(' ')
});

export const getRefreshThreshold = () => getTokenSegment(2);

interface RefreshTokenIfExpiredHandler {
    (baseUrl?: string): Promise<boolean>;
    [refreshTokenRequestRefSymbol]?: Promise<Response> | null;
}

export const refreshTokenIfExpired: RefreshTokenIfExpiredHandler = async (
    baseUrl = API_URL
) => {
    const isExpired = shouldRefreshToken();

    if (!isExpired) {
        return false;
    }

    let refreshTokenSharedRequest = refreshTokenIfExpired[refreshTokenRequestRefSymbol];

    if (!refreshTokenSharedRequest) {
        refreshTokenSharedRequest =
            refreshTokenIfExpired[refreshTokenRequestRefSymbol] =
            fetch(`${baseUrl}${route(REFRESH_TOKEN_PATH)}`, {
                method: 'POST',
                headers: {
                    ...headers,
                    ...getAuthorizationHeader()
                }
            });
    }

    const response = await refreshTokenSharedRequest;

    if (!response.ok) {
        removeToken();
        refreshTokenIfExpired[refreshTokenRequestRefSymbol] = null;

        return false;
    }

    const { data, code } = await response.json();

    if (isUnauthorized(code)) {
        refreshTokenIfExpired[refreshTokenRequestRefSymbol] = null;

        throw new Error('Unauthorized');
    }

    setToken(
        data.access_token,
        data.token_type,
        data.expires_in
    );

    refreshTokenIfExpired[refreshTokenRequestRefSymbol] = null;

    return true;
};

function shouldRefreshToken() {
    const expiresIn = getRefreshThreshold();

    if (typeof expiresIn !== 'string') {
        return false;
    }

    if (!expiresIn) {
        return true;
    }

    return isAfter(new Date(), new Date(+expiresIn));
};

function getTokenRefreshThreshold(expiresIn: number) {
    const refreshThresholdDate = addSeconds(
        new Date(),
        expiresIn / REFRESH_TOKEN_THRESHOLD_DIVIDER
    );

    return +refreshThresholdDate; // convert to timestamp
}

function serializeToken(...args: [string, string, number]) {
    return args.join(':');
}

function deserializeToken(token: string) {
    return token.split(':');
}

function getTokenSegment(segment: number) {
    const token = Cookie.get(TOKEN_COOKIE_NAME);

    if (!token) {
        return null;
    }

    return deserializeToken(token)[segment];
}
