import api from "infrastructure/axios";
import { PaymentMethodApiRoutes } from "./consts";
import type { PaginatedResourceResponse } from "interface/api";
import type { PaymentMethod } from "./types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";

export const fetchPaymentMethods = (config?: AxiosRequestConfig) =>
    api.get<undefined, AxiosResponse<PaginatedResourceResponse<PaymentMethod>>>(
        PaymentMethodApiRoutes.Get,
        config
    );
