export const enum ResponseCode {
    Success = 200,
    Created = 201,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    UnprocessableEntity = 422,
    InternalServerError = 500
}

export const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json'
};

export const verificationStatus = {
    verified: 'verified',
    expired: 'expired',
    failed: 'failed'
} as const;

export const VERIFICATION_STATUS_QUERY_PARAMETER = 'status';
