import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

// Create a theme instance.
export default extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: '#2A2C64'
                },
                success: {
                    main: '#00EBA0'
                },
                error: {
                    main: '#EB5757'
                }
            }
        }
    },
    typography: {
        fontFamily: [
            'Lexend',
            'sans-serif'
        ].join(','),
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
                InputProps: {
                    disableUnderline: true
                }
            },
            styleOverrides: {
                root: ({ theme }) =>
                    theme.unstable_sx({
                        '& .MuiInputLabel-root': {
                            fontWeight: 300,
                            fontSize: '1rem',
                            color: 'var(--macro-input-placeholder-color)'
                        },
                        '& .MuiInputBase-root': {
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'var(--macro-input-border-color)',
                            borderRadius: '0.5rem',
                            backgroundColor: theme.vars.palette.common.white,
                            '&.Mui-focused': {
                                backgroundColor: theme.vars.palette.common.white
                            },
                            '&.Mui-error': {
                                borderColor: theme.vars.palette.error.main,
                                boxShadow: 'none',
                                ':hover': {
                                    borderColor: theme.vars.palette.error.main
                                }
                            },
                            ':hover': {
                                backgroundColor: theme.vars.palette.common.white,
                                borderColor: 'var(--macro-input-border-color-hover)'
                            }
                        }
                    })
            }
        },
        MuiCssBaseline: {
            styleOverrides: _ => `
                :root {
                    --macro-background-secondary-action: #E4E4EE;
                    --macro-background-main: #F6F5FA;
                    --macro-text-secondary: #C2C3E0;
                    --macro-blue-dark: #2A2C64;
                    --macro-blue-light: #6968A4;
                    --macro-grey-light: #F6F5FA;
                    --macro-grey-4: #BDBDBD;
                    --macro-success-color: #00EBA0;
                    --macro-text-blue-light: #9696C5;
                    --macro-text-violet: #2C2C64;
                    --macro-bg-violet: #8C8BB9;
                    --macro-contactus-blue: #222351;
                    --terms-and-condition-color: #551A8B;
                    --macro-font-size-h1: 2rem;
                    --macro-font-weight-h1: 700;
                    --macro-line-height-h1: 40 / 32;

                    --macro-font-size-regular: 1rem;
                    --macro-font-weight-regular: 400;
                    --macro-line-height-regular: 24 / 20;

                    --macro-font-size-labelled-group: 1.25rem;
                    --macro-font-weight-labelled-group: 500;
                    --macro-line-height-labelled-group: 24 / 20;
                    --macro-color-labelled-group: var(--macro-blue-dark);

                    --macro-input-placeholder-color: #8C8BB9;
                    --macro-input-border-color: rgb(206, 213, 227);
                    --macro-input-border-color-hover: rgb(112, 133, 174);
                }
                body {
                    background-color: var(--macro-background-main);
                }
                a {
                    text-decoration: none;
                }
            `
        }
    }
});
