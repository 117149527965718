import { useAuth } from "feature/account/hooks";
import { PropsWithChildren, memo } from "react";
import AuthAction from "ui/molecules/AuthAction/AuthAction";

const SigninWidget = ({ children }: PropsWithChildren) => {
    const authProps = useAuth();

    return (
        <>
            {children || (
                <AuthAction
                    {...authProps}
                />
            )}
        </>
    );
};

export default memo(SigninWidget);
