type RouteArgs = {
    readonly segments: [string, ...Array<string>];
    readonly query?: Array<string>[];
};

const route = (routeName: string | RouteArgs, ...params: Array<string>) => {
    if (typeof routeName === 'object') {
        const { segments = [], query = [] } = routeName;

        const path: string = route(...segments as RouteArgs['segments']);

        const urlSearchParams = new URLSearchParams(query).toString();

        return `${path}${urlSearchParams ? `?${urlSearchParams}` : ''}`;
    }

    return `/${[routeName, ...params].join('/')}`;
};

export const param = (param: string) => `:${param}`;

export const getSearchParam = <TReturn>({
    searchQueryName = '',
    url = window.location.href,
    strategy = 'get'
}) =>
    new URL(url).searchParams[strategy as 'get' | 'getAll'](searchQueryName) as TReturn;

export default route;
