import Lazy from 'ui/layouts/Lazy';
import Component, { Props } from "./Component";

const BasicLight = (props: Props) => (
    <Lazy>
        <Component
            {...props}
        />
    </Lazy>
);

export default BasicLight;
