import { SendMoneyStep } from "feature/send-money/types";
import { QUERY_PARAMETER } from "feature/transaction/consts";
import { memo } from "react";
import { useParams } from "react-router-dom";
import Responsive from "ui/layouts/Responsive";
import { FirstStepDesktop, FirstStepMobile } from "./FirstStep";
import { SecondStepDesktop, SecondStepMobile } from "./SecondStep";

const SendMoney = () => {
    const params = useParams();

    switch (params[QUERY_PARAMETER]) {
        case SendMoneyStep.First:
            return (
                <Responsive
                    xs={FirstStepMobile}
                    md={FirstStepDesktop}
                />
            );

        default: {
            return (
                <Responsive
                    xs={SecondStepMobile}
                    md={SecondStepDesktop}
                />
            );
        }
    }
};

export default memo(SendMoney);
