export const enum Locale {
    EN = 'en'
};

export const enum Translations {
    Common = 'common',
    HomePage = 'home-page',
    TransferMoney = 'transfer-money',
    Result = 'result',
    Terms = 'terms',
    Privacy = 'privacy',
    NotFound = 'not-found',
    Signup = 'signup',
    Dashboard = 'dashboard',
    SendMoney = 'send-money',
    Login = 'login',
    ConfirmEmail = 'confirm-email',
    Iban = 'iban',
    ForgotPassword = 'forgot-password',
    ResetPassword = 'reset-password'
};

