import { create } from "zustand";
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { State, Actions } from "./types";
import state from "./state";
import { submitStep } from "./actions";
import { Repository } from "interface/store";

const useStore = create<Repository<State, Actions>>()(
    devtools(immer((set, get) => ({
        ...state,
        submitStep: submitStep(set, get)
    })))
);

export default useStore;
