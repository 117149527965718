import { PropsWithChildren, ReactNode, memo } from "react";
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import type { AppBarProps } from "ui/atoms/AppBar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "ui/atoms/Toolbar";
// import Box from "@mui/material/Box";
// import Drawer from "ui/atoms/Drawer";
// import Nav from "ui/molecules/Nav";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { mailto } from "util/support";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export type Props = AppBarProps & {
    readonly onClick?: () => void;
    readonly backButtonSlot?: ReactNode;
    readonly contactUsButtonSlot?: ReactNode;
    readonly logoutButtonSlot?: ReactNode;
};

const Header = ({
    children,
    backButtonSlot,
    contactUsButtonSlot,
    logoutButtonSlot,
    open,
    onClick = () => { },
    ...restAppBarProps
}: PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <AppBar
                position="fixed"
                component='nav'
                {...restAppBarProps}
            >
                <Container>
                    <Toolbar
                        sx={{
                            xs: {
                                display:'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',

                            },
                            paddingLeft: {
                                xs: 0
                            },
                            md: {
                                display: 'flex'
                            },
                            justifyContent: {
                                xs: 'space-between',
                                md: 'space-between',
                            },
                            alignItems: {
                                xs: 'center',
                                md: 'center',
                            }
                        }}
                    >
                        {backButtonSlot}
                        {/* <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onClick}
                            edge="start"
                            sx={{
                                justifySelf: 'flex-end',
                                display:{
                                    xs:"flex",
                                    md:'none'
                                }
                            }}
                        >
                            <MenuIcon />
                        </IconButton> */}

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                textAlign:{
                                    md: 'center',
                                    xs: 'start'
                                },
                                display :'flex' ,
                                justifyContent: {
                                    xs: 'center'
                                },

                                [theme.breakpoints.down('md')]: {
                                    flexGrow: 1,
                                },
                                overflow: 'initial'
                            }}
                        >
                            {children}
                        </Typography>
                        <Stack
                            direction='row'
                            spacing={2}
                        >
                            {contactUsButtonSlot || (
                                <Box
                                    sx={{
                                        display: 'flex'
                                    }}>
                                    <Button
                                        disableElevation
                                        href={mailto()}
                                        sx={{
                                            height: "36px",
                                            width: "108px",
                                            borderRadius: '6px',
                                            p: '8px 16px',
                                            color: 'var(--macro-input-placeholder-color)',
                                            textTransform: 'capitalize',
                                            backgroundColor: 'rgba(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity))',
                                            marginRight: "10px",
                                            ':hover': {
                                                borderColor: "var(--macro-grey-4)"
                                            },
                                            display: {
                                                xs: "none",
                                                md: 'flex'
                                            }
                                        }}
                                    >
                                        {t('contact-us')}
                                    </Button>
                                </Box>
                            )}
                            {logoutButtonSlot}
                        </Stack>
                    </Toolbar>
                </Container>
            </AppBar>
            {/* <Box component='nav'>
                <Drawer
                    open={open}
                    onClose={onClick}
                    variant="temporary"
                    anchor="right"
                >
                    <Nav />
                </Drawer>
            </Box> */}
        </>
    )
};

Header.defaultProps = {
    backButtonSlot: null,
    contactUsButtonSlot: null
};

export default memo(Header);
