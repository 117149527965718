import { memo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Translations } from "config/i18n";

export type Props = {
    readonly translation: Translations;
};

const Legal = ({ translation }: Props) => {
    const { t } = useTranslation(translation);

    const translationTokenRepository = new Map<Translations, Array<string>>()
        .set(Translations.Terms, [
            'terms_and_conditions',
            'payment_processing',
            'payment_information',
            'fees',
            'refunds',
            'security',
            'limitation_of_liability',
            'governing_law',
            'modifications_to_agreement',
            'termination',
            'entire_agreement'
        ])
        .set(Translations.Privacy, [
            'at_narvitopup',
            'information_we_collect',
            'personal_information',
            'website_usage_information',
            'communication_information',
            'how_we_use_your_information',
            'we_use_your_information',
            'to_process_your_payments',
            'to_prevent_fraud',
            'to_comply_with_legal_requirements',
            'to_improve_our_services',
            'to_send_marketing',
            'how_we_protect_your_information',
            'we_take_reasonable_measures',
            'your_gdpr_rights',
            'right_of_access',
            'right_to_rectification',
            'right_to_erasure',
            'right_to_restrict_processing',
            'right_to_data_portability',
            'right_to_object',
            'we_may_update_this'
        ]);

    return (
        <>
            {translationTokenRepository
                .get(translation)
                ?.map(translationToken =>
                    <Box
                        key={translationToken}>
                        <Box
                            sx={{
                                fontWeight: 600,
                                p: 1,
                                paddingLeft:0,
                                display: 'flex',
                                justifyContent: "start"
                            }}
                        >
                            {t(`${translationToken}.title`)}
                        </Box>
                        <Typography>
                            {t(`${translationToken}.content`)}
                        </Typography>
                    </Box>
                )}
        </>
    );
};

export default memo(Legal);
