import { countryToDefaultCurrencyMap } from "config/business";
import type { Option } from "interface/entity";
import { FormFields, SecondStepState } from "./types";

export function useDefaultPaymentMethodCurrency<TStep extends Omit<SecondStepState, FormFields.Iban>>({
    paymentMethodCountries,
    paymentMethodCurrencies,
    defaultValues
}: {
    readonly paymentMethodCountries: Option[];
    readonly paymentMethodCurrencies: Option[];
    readonly defaultValues: TStep;
}) {
    const getCountryDefaultCurrency = (country: Option | null) => {
        for (const currency of paymentMethodCurrencies) {
            const defaultCurrency = countryToDefaultCurrencyMap.get(String(country?.value));

            if (currency.value === defaultCurrency) {
                return defaultCurrency;
            }
        }
    };

    const getCurrencyOptions = (country: Option | null) => {
        const coutryDefaultCurrency = getCountryDefaultCurrency(country);

        if (coutryDefaultCurrency) {
            return paymentMethodCurrencies
                .filter(currency => Object.is(currency.value, coutryDefaultCurrency));
        }

        return paymentMethodCurrencies;
    };

    const getDefaultValues = () => {
        const defaultCurrency = paymentMethodCurrencies[0]?.value ?? '';

        const formDefaultValues = {
            ...defaultValues,
            [FormFields.Currency]: defaultCurrency
        };

        if (paymentMethodCountries.length === 1) {
            const country = paymentMethodCountries[0];

            return {
                ...formDefaultValues,
                [FormFields.Country]: country,
                [FormFields.Currency]: getCountryDefaultCurrency(country) || defaultCurrency
            };
        }

        return formDefaultValues;
    };

    return {
        getCountryDefaultCurrency,
        getCurrencyOptions,
        getDefaultValues
    };
};
