import type { Breakpoint, Theme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function useBreakpoint(breakpoint: Breakpoint) {
    const matches = useMediaQuery(
        (theme: Theme) => theme.breakpoints.up(breakpoint)
    );

    return {
        matches,
        breakpoint
    };
};
