import { Fragment, memo } from "react";
import Basic from "ui/layouts/Basic";
import Responsive from "ui/layouts/Responsive";
import { Desktop } from "ui/molecules/Footer";

const TransactionResult = () => {
    return (
        <Basic
            headerProps={{
                position: 'absolute',
                elevation: 0,
                color: 'transparent',
                logoutButtonSlot: <></>
            }}
            containerProps={{
                sx: {
                    height: '100vh',
                    maxWidth: 'initial !important',
                    placeContent: 'initial',
                    alignItems: 'flex-start',
                    minHeight: 650,
                    pb: {
                        xs: 8.1,
                        md: 15
                    }
                }
            }}
            footerSlot={(
                <Responsive
                    xs={Fragment}
                    md={Desktop}
                />
            )}
            logoType="dark"
        />
    );
};

export default memo(TransactionResult);
