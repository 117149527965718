import { ResponseCode } from "config/api";
import { FRACTION_DIGITS, SUPPORT_EMAIL } from "config/general";
import type { Option } from "interface/entity";
import { ChangeEvent } from "react";

export function convertToOption<T extends unknown>(values: Array<T>): Array<Option> {
    return values.map(value => {
        const label = String(value).trim();

        return {
            value: label,
            label
        };
    });
};

export const mailto = (email: string = SUPPORT_EMAIL): string => `mailto:${email}`;

export const decorateWithCallback = <T extends HTMLInputElement>(
    handleChange: (event: ChangeEvent<T>) => void,
    modifier: (value: T['value']) => T['value']
) =>
    (event: ChangeEvent<T>) => {
        event.target.value = modifier(event.target.value);
        handleChange(event);
    };

export const trimSpaces = <T extends Record<string | number, unknown>>(value: object) =>
    Object.entries(value).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: typeof value === 'string'
            ? value.trim()
            : value
    }), {} as T);

export const capitalizeFirstLetter = (str: string) =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const isUnauthorized = (code: number) =>
    [ResponseCode.Unauthorized].includes(code);

export const parseNumber = (value: string, fractionLength = FRACTION_DIGITS) => {
    if (value.length <= 0) {
        return value;
    }

    const amount = Number(value);

    if (!Number.isNaN(amount)) {
        const [integer, fraction = ''] = value.split('.');

        return value.includes('.')
            ? `${integer}.${fraction.slice(0, fractionLength)}`
            : integer;
    }
};

export const inRange = (x: number, min: number, max: number) =>
    x >= min && x <= max;