import { Outlet } from "react-router-dom";
import { ReactNode, memo } from "react";
import Container from '@mui/material/Container';
import Box, { BoxProps } from "@mui/material/Box";
import Lazy from "ui/layouts/Lazy";
import Header, { type HeaderProps } from "ui/molecules/Header";
import Footer from "ui/molecules/Footer";
import Logo, { type LogoProps } from "ui/atoms/Logo";
import useApp from "./useApp";
import SigninWidget from "ui/widgets/SigninWidget/SigninWidget";

export type Props = {
    readonly footerSlot?: ReactNode;
    readonly backButtonSlot?: ReactNode;
    readonly containerProps?: BoxProps;
    readonly headerProps?: Omit<HeaderProps, 'onClick'>;
    readonly logoType?: LogoProps['type'];
};

const App = ({
    backButtonSlot,
    footerSlot,
    logoType,
    headerProps = {},
    containerProps = {}
}: Props) => {
    const { isNavbarOpen, onToggleNavbar } = useApp();

    const renderBackButton = ({ sx }: BoxProps) => (
        Boolean(backButtonSlot) && (
            <Box
                sx={sx}
            >
                {backButtonSlot}
            </Box>
        )
    );

    return (
        <Lazy>
            <Header
                {...headerProps}
                open={isNavbarOpen}
                logoutButtonSlot={(
                    <SigninWidget>
                        {headerProps.logoutButtonSlot}
                    </SigninWidget>
                )}
                backButtonSlot={renderBackButton({
                    sx: {
                        display: {
                            xs: 'block',
                            md: 'none'
                        },
                        position: 'absolute',
                        bottom: '20%'
                    }
                })}
                onClick={onToggleNavbar}
            >
                <Box
                    sx={{
                        position: 'relative'
                    }}
                >
                    <Logo type={logoType} />
                    {renderBackButton({
                        sx: {
                            display: {
                                xs: 'none',
                                md: 'block'
                            },
                            position: 'absolute',
                            right: 0,
                            bottom: -90
                        }
                    })}
                </Box>
            </Header >
            <Container
                component='main'
                sx={{
                    display: 'grid',
                    placeContent: 'center',
                    py: 15,
                    bgcolor: 'var(--macro-background-main)',
                    ...(containerProps.sx ?? {})
                }}
            >
                <Outlet />
            </Container>
            {footerSlot}
        </Lazy>
    );
};

App.defaultProps = {
    footerSlot: (
        <Footer />
    )
};

export default memo(App);
