import { ApiStatus } from "interface/api";
import { getToken } from "util/token";
import { accountFactory } from "./helpers";

const getState = () => ({
    status: getToken()
        ? ApiStatus.Pending
        : ApiStatus.Failed,
    account: accountFactory(),
});

export default getState;
