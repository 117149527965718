import { memo } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import type { ButtonBaseProps } from "@mui/material/ButtonBase";
import { useTranslation } from "react-i18next";
import { Translations } from "config/i18n";
import { Theme, useMediaQuery } from "@mui/material";

type Props = Pick<ButtonBaseProps, 'onClick'>;

const BackButton = ({ onClick }: Props) => {
    const { t } = useTranslation(Translations.Common);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    if (isMobile) {
        return (
            <IconButton
                onClick={onClick}
            >
                <ArrowBackIcon color="primary" />
            </IconButton>
        );
    }

    return (
        <Button
            id="back-button"
            onClick={onClick}
            startIcon={<ArrowBackIcon />}
            sx={{
                textTransform: 'capitalize',
            }}
        >
            {t('go-back')}
        </Button>
    );
};

export default memo(BackButton);
