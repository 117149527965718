import { FormFields } from "feature/transaction/types";
import { SendMoneyStep, State } from "./types";

export const firstStepInitialState = {
    [FormFields.PaymentMethod]: '',
    [FormFields.FirstName]: '',
    [FormFields.LastName]: ''
};

export const secondStepInitialState = {
    [FormFields.Country]: null,
    [FormFields.Amount]: '',
    [FormFields.Currency]: ''
};

const state: State = {
    [SendMoneyStep.First]: {
        isValid: false,
        data: {
           ...firstStepInitialState
        }
    },
    [SendMoneyStep.Second]: {
        isValid: false,
        data: {
            ...secondStepInitialState
        }
    }
};

export default state;
