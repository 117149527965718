import { memo, Fragment, useEffect, useCallback, useRef, PropsWithChildren } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import BackButton from "ui/atoms/BackButton";
import App from "ui/layouts/App";
import StepperContextProvider, { StepperContextProviderProps } from "./StepperContextProvider";
import {
    Location,
    NavigateFunction,
    Params,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import type { AppProps } from "ui/layouts/App";

type LocationWithParams = Location & Readonly<Params<string>>;

export type StepperProps = StepperContextProviderProps & {
    readonly isStepValid: (location: LocationWithParams) => boolean;
    readonly isInitialStep: (location: LocationWithParams) => boolean;
    readonly back?: (router: NavigateFunction) => void;
    readonly layoutProps?: AppProps;
};

const Stepper = ({
    back,
    next,
    isStepValid,
    isInitialStep,
    children,
    layoutProps = {}
}: PropsWithChildren<StepperProps>) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const handlersRef = useRef({
        isStepValid,
        back
    });

    const paramsRef = useRef(params);

    const onGoBack = useCallback(() => {
        back?.(navigate);
    }, [back, navigate]);

    useEffect(() => {
        handlersRef.current = {
            isStepValid,
            back
        };
    }, [isStepValid, back]);

    useEffect(() => {
        paramsRef.current = params;
    }, [params]);

    useEffect(() => {
        const { isStepValid, back } = handlersRef.current;

        if (!isStepValid({ ...location, ...paramsRef.current })) {
            back?.(navigate);
        }
    }, [location, navigate]);

    /*useEffect(() => {
        const { events, query } = router;

        const handleRouteChange = () => {
            const { isStepValid, back } = handlersRef.current;

            if ((query[queryParameterName] !== currentStepRef.current) && !isStepValid(router)) {
                back?.(router);
            };
        };

        handleRouteChange();

        events.on('routeChangeComplete', handleRouteChange);

        currentStepRef.current = String(query[queryParameterName]);
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        }
    }, [router, queryParameterName]);*/

    return (
        <StepperContextProvider
            next={next}
        >
            {children || (
                <App
                    footerSlot={isMobile
                        ? <Fragment />
                        : undefined}
                    backButtonSlot={!isInitialStep({ ...location, ...params }) && (
                        <BackButton
                            onClick={onGoBack}
                        />
                    )}
                    {...layoutProps}
                />
            )}
        </StepperContextProvider>
    );
};

export default memo(Stepper);
