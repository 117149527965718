import { FirstStepState, FormFields, SecondStepState, TransferMoneyStep } from "feature/transaction/types";
import type { FormProps, FormValidator } from "interface/form";
import { number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX, LENGTH_REGEX, NUMBER_REGEX } from "config/validation";

type FormData = FirstStepState & SecondStepState;

export const validator: Record<keyof FormData, FormValidator> = {
    [FormFields.FirstName]: {
        required: true,
        minLength: 2,
        maxLength: 50
    },
    [FormFields.LastName]: {
        required: true,
        minLength: 2,
        maxLength: 50
    },
    [FormFields.Email]: {
        type: 'email',
        required: true,
        maxLength: 64
    },
    [FormFields.PaymentMethod]: {
        required: true
    },
    [FormFields.Country]: {
        required: true
    },
    [FormFields.Amount]: {
        required: true,
        min: 10,
        max: 1000,
        maxLength: 4,
        pattern: NUMBER_REGEX
    },
    [FormFields.Currency]: {
        required: true
    },
    [FormFields.Iban]: {
        required: true,
        minLength: 16,
        maxLength: 16,
        pattern: NUMBER_REGEX
    }
};

export const useFirstStepSchema = ({ translations }: Pick<FormProps<unknown>, 'translations'>) => {
    const { t } = useTranslation(translations);

    return object()
        .shape({
            [FormFields.FirstName]: string()
                .min(validator[FormFields.FirstName].minLength!, `${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.FirstName}.validation.minLength`, { minLength: validator[FormFields.FirstName].minLength })}`)
                .max(validator[FormFields.FirstName].maxLength!, `${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.FirstName}.validation.maxLength`, { maxLength: validator[FormFields.FirstName].maxLength })}`)
                .required(),
            [FormFields.LastName]: string()
                .min(validator[FormFields.LastName].minLength!, `${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.LastName}.validation.minLength`, { minLength: validator[FormFields.LastName].minLength })}`)
                .max(validator[FormFields.LastName].maxLength!, `${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.LastName}.validation.maxLength`, { maxLength: validator[FormFields.LastName].maxLength })}`)
                .required(),
            // [FormFields.Email]: string()
            //     .required(`${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.Email}.validation.required`)}`)
            //     .matches(EMAIL_REGEX, `${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.Email}.validation.matches`)}`)
            //     .max(validator[FormFields.Email].maxLength!, `${t(`${TransferMoneyStep.First}.form.formElements.${FormFields.Email}.validation.maxLength`, { maxLength: validator[FormFields.Email].maxLength })}`),
            [FormFields.PaymentMethod]: string()
                .required()
        });
}
export const useSecondStepSchema = ({ translations }: Pick<FormProps<unknown>, 'translations'>) => {
    const { t } = useTranslation(translations);
    const getCurrencyValue = () =>
        (document.getElementById(FormFields.Currency) as HTMLInputElement)
            ?.value;

    return object()
        .shape({
            [FormFields.Country]: object()
                .required(`${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Country}.validation.required`)}`),
            [FormFields.Amount]: number()
                .required(`${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.required`)}`)
                .typeError(`${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.required`)}`)
                .min(validator[FormFields.Amount].min!, () =>
                    `${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.min`, {
                        value: validator[FormFields.Amount].min,
                        [FormFields.Currency]: getCurrencyValue()
                    })}`
                )
                .max(validator[FormFields.Amount].max!, () =>
                    `${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.max`, {
                        value: validator[FormFields.Amount].max,
                        [FormFields.Currency]: getCurrencyValue()
                    })}`
                ),
            [FormFields.Currency]: string()
                .required(),
            [FormFields.Iban]: string()
                .min(validator[FormFields.Iban].minLength!, `${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Iban}.validation.minLength`, { minLength: validator[FormFields.Iban].minLength })}`)
                .max(validator[FormFields.Iban].maxLength!, `${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Iban}.validation.maxLength`, { maxLength: validator[FormFields.Iban].maxLength })}`)
                .matches(LENGTH_REGEX, `${t(`${TransferMoneyStep.Second}.form.formElements.${FormFields.Iban}.validation.matches`)}`)
                .required()
        });
}
