import { memo, lazy } from "react";
import Responsive from "ui/layouts/Responsive";

const Desktop = lazy(() => import('./Desktop'));
const Mobile = lazy(() => import('./Mobile'));

const NotFound = () => (
    <Responsive
        xs={Mobile}
        md={Desktop}
    />
);

export default memo(NotFound);