export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || 'narvi';
export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || 'topup';
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL || 'info@narvitopup.com';

export const EXTERNAL_URL = process.env.REACT_APP_EXTERNAL_URL || 'https://narvi.com';

export const API_URL = process.env.REACT_APP_API_URL;

export const IBAN_PREFIX = process.env.REACT_APP_IBAN_PREFIX || 'FI';

export const FRACTION_DIGITS = 2;
