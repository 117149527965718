import { memo } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Routes from "config/routes/web";
import route from "util/route";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                color: 'var(--macro-text-blue-light)',
                bgcolor: theme => ({
                    md: theme.vars.palette.primary.main
                }),
                width: '100%',
                position: {
                    md: 'fixed'
                },
                bottom: 0
            }}
        >
            <Container
                sx={{
                    px: {
                        xs: 0,
                        md: 6
                    },
                    py: {
                        xs: 0,
                        md: 4
                    },
                    display: {
                        md: 'flex'
                    },
                    textAlign: {
                        xs: 'center',
                        md: 'initial'
                    },
                    justifyContent: 'space-between'
                }}
            >
                <Stack
                    direction={{
                        xs: 'column',
                        md: 'row'
                    }}
                    spacing={{
                        xs: 0,
                        md: 4
                    }}
                    divider={(
                        <Divider
                            sx={{
                                display: {
                                    xs: 'initial',
                                    md: 'none'
                                },
                                width: '100%',
                                maxWidth: .2,
                                alignSelf: 'center',
                            }}
                        />
                    )}
                    sx={{
                        p: {
                            xs: 3.5,
                            md: 0
                        },
                        bgcolor: theme => ({
                            xs: 'var(--macro-grey-light)',
                            md: theme.vars.palette.primary.main
                        })
                    }}
                >
                    <Link to={route(Routes.Terms)}>
                        <Box
                            sx={{
                                mb: {
                                    xs: 2.9,
                                    md: 0
                                },
                                color: 'var(--macro-text-blue-light)'
                            }}
                        >
                            {t('terms')}
                        </Box>
                    </Link>
                    <Link to={route(Routes.Privacy)}>
                        <Box
                            sx={{
                                pt: {
                                    xs: 2.9,
                                    md: 0
                                },
                                color: 'var(--macro-text-blue-light)'
                            }}
                        >
                            {t('privacy')}
                        </Box>
                    </Link>
                </Stack>
                <Box
                    sx={{
                        fontSize: 13,
                        bgcolor: theme => theme.vars.palette.primary.main,
                        p: {
                            xs: 2.7,
                            md: 0
                        }
                    }}
                >
                    {t('copyright', {
                        year: new Date().getFullYear()
                    })}
                </Box>
            </Container>
        </Box>
    );
};

export default memo(Footer);