
import {
    State,
    FirstStepState,
    SecondStepState,
    Actions,
    SendMoneyStep
} from "./types";
import type { Repository, StateHandler } from "interface/store";
import { FormFields, TopupResult } from "feature/transaction/types";
import { processTopup } from "feature/transaction/api";
import { trimSpaces } from "util/support";
import useStore from "feature/account/useStore";

export const submitStep = (set: StateHandler<State>, get: () => Repository<State, Actions>) =>
    async (formData: FirstStepState | SecondStepState) => {
        if (isFirstStep(formData)) {
            set(state => {
                state[SendMoneyStep.First] = {
                    data: {
                        ...state[SendMoneyStep.First].data,
                        ...formData
                    },
                    isValid: true
                };
            });
            return Promise.resolve({
                redirectUrl: ''
            } as TopupResult);
        }

        const firstStep = get()[SendMoneyStep.First].data;
        const account = useStore.getState().account!;

        const { data: topupPayload } = await processTopup(
            trimSpaces({
                firstName: firstStep[FormFields.FirstName],
                lastName: firstStep[FormFields.LastName],
                email: account.email,
                method: firstStep[FormFields.PaymentMethod],
                iban: account.iban,
                countryCode: String(formData[FormFields.Country]?.value),
                currency: formData[FormFields.Currency],
                amount: formData[FormFields.Amount]
            })
        );

        return topupPayload.data;
    };

function isFirstStep(formData: FirstStepState | SecondStepState): formData is FirstStepState {
    return FormFields.PaymentMethod in formData;
}
