import type { StateHandler } from "interface/store";
import type { Account, Credentials, LoginResponse, PasswordResetRequest, State } from "./types";
import {
    fetchAccount as fetchAccountRequest,
    updateAccount as updateAccountRequest,
    logout as logoutRequest
} from "./api";
import { ApiStatus } from "interface/api";
import { setTokenFromResponse } from "./helpers";
import type { AxiosResponse } from "axios";
import { trimSpaces } from "util/support";
import { removeToken } from "util/token";
import { resetPassword as resetPasswordRequest } from "./api";

export const sign = <
    TRequestData extends Credentials,
    TResponseData extends Promise<AxiosResponse<LoginResponse>>
>(set: StateHandler<State>, apiHandler: (data: TRequestData) => TResponseData) =>
    async (credentials: TRequestData) => {
        try {
            const { data: accessTokenResponse } = await apiHandler(credentials);

            if (!accessTokenResponse.success) {
                throw new Error(accessTokenResponse.message);
            }

            setTokenFromResponse(accessTokenResponse);

            const { data: { data: account } } = await fetchAccountRequest();

            set(state => {
                state.status = ApiStatus.Succeeded;
                state.account = account;
            });

            return account;
        } catch (error) {
            set(state => {
                state.status = ApiStatus.Failed;
                state.account = null;
            });

            throw error;
        }
    };

export const resetPassword = (set: StateHandler<State>) =>
    async (credentials: PasswordResetRequest) => {
        try {
            const { data: response } = await resetPasswordRequest(credentials);

            return response;
        }finally {
            set(state => {
                state.status = ApiStatus.Failed;
                state.account = null;
            });
        }
    };

export const fetchAccount = (set: StateHandler<State>) =>
    async () => {
        set(state => {
            state.status = ApiStatus.Pending;
        });

        try {
            const { data: { data: account } } = await fetchAccountRequest();

            set(state => {
                state.status = ApiStatus.Succeeded;
                state.account = account;
            });

            return account;
        } catch (error) {
            set(state => {
                state.status = ApiStatus.Failed;
                state.account = null;
            });

            throw error;
        }
    };

export const updateAccount = (set: StateHandler<State>) =>
    async (data: Partial<Account>) => {
        try {
            const { data: { data: account } } = await updateAccountRequest(
                trimSpaces(data)
            );

            set(state => {
                state.account = account;
            });

            return account;
        } catch (error) {
            set(state => {
                state.status = ApiStatus.Failed;
                state.account = null;
            });

            throw error;
        }
    };

export const logout = (_: StateHandler<State>) =>
    async () => {
        try {
            await logoutRequest();
        } finally {
            removeToken();
        }
    };
