import { PaymentMethod } from "config/business";
import { getPaymentMethodsMapping } from "./helpers";
import type { State } from "./types";

export const selectPaymentMethods = (state: State) => state.paymentMethods?.data ?? [];

export const selectPaymentMethodsMapping = (state: State) => {
    const paymentMethods = selectPaymentMethods(state);

    if (paymentMethods) {
        return getPaymentMethodsMapping(paymentMethods);
    }

    return new Map<PaymentMethod, string>();
};
