import { ReactNode, memo } from "react";
import Box, { BoxProps } from "@mui/material/Box";

type Props = {
    readonly labelSlot: ReactNode;
    readonly ibanSlot: ReactNode;
    readonly sx?: BoxProps['sx'];
};

const ReceivingIban = ({ labelSlot, ibanSlot, sx = {} }: Props) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, auto)',
            columnGap: '10px',
            justifyContent: 'center',
            ...sx
        }}
    >
        <Box
            component='span'
            sx={{
                color: 'var(--macro-blue-light)',
                fontSize: {
                    xs: '15px'
                }
            }}
        >
            {labelSlot}
        </Box>
        <Box
            component='span'
            sx={{
                color: 'primary.main',
                fontWeight: 600
            }}
        >
            {ibanSlot}
        </Box>
    </Box>
);

export default memo(ReceivingIban);
