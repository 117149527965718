import type { } from '@mui/material/themeCssVarsAugmentation';
import { styled, Theme, PaletteColor, ColorSystem } from '@mui/material/styles';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import Button, { ButtonProps } from '@mui/material/Button';
import { BoxProps } from '@mui/material/Box';
import { LinkProps } from 'react-router-dom';
import { Palette } from 'config/ui';

type CssVarsPaletteType = Omit<
    ColorSystem['palette'],
    | 'colorScheme'
    | 'mode'
    | 'contrastThreshold'
    | 'tonalOffset'
    | 'getContrastText'
    | 'augmentColor'
>;

type CommonProps =
    & BoxProps
    & Partial<LinkProps>
    & {
        readonly color?: keyof CssVarsPaletteType;
    };

const styleOverrides = ({ theme, color = Palette.Success }: Readonly<{
    theme: Theme;
    color?: keyof CssVarsPaletteType;
}>) => ({
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: (theme.vars.palette[color] as PaletteColor).main,
    borderRadius: 12,
    width: '100%',
    maxWidth: 302,
    height:'64px',
    color: new Map<string, string>()
        .set(Palette.Primary, theme.vars.palette.common.white)
        .set(Palette.Success, theme.vars.palette.primary.main)
        .get(color) ?? theme.vars.palette.common.white,
    fontWeight: new Map<string, number>()
        .set(Palette.Primary, 500)
        .set(Palette.Success, 600)
        .get(color) ?? 600,
    fontSize: 19,
    textTransform: 'none',
    ':hover': {
        backgroundColor: (theme.vars.palette[color] as PaletteColor).main
    }
}) as const;

export const LoadingActionButton = styled(LoadingButton)<
    LoadingButtonProps & CommonProps
>(({ theme, color = Palette.Success }) => styleOverrides({ theme, color }));

export const ActionButton = styled(Button)<
    ButtonProps & CommonProps
>(({ theme, color = Palette.Success }) => styleOverrides({ theme, color }));
