import openBankingLogo from 'asset/images/paymentMethods/openbanking.png';
import bancontactLogo from 'asset/images/paymentMethods/bancontact.png';
import sofortLogo from 'asset/images/paymentMethods/directpay.png';
import epsLogo from 'asset/images/paymentMethods/eps.png';
import giropayLogo from 'asset/images/paymentMethods/giropay.png';
import idealLogo from 'asset/images/paymentMethods/ideal.png';
import p24Logo from 'asset/images/paymentMethods/p24.png';
import multibancoLogo from 'asset/images/paymentMethods/multibanco.png';
import paysafeCardLogo from 'asset/images/paymentMethods/paysafecard.png';

export const enum PaymentMethod {
    OpenBanking = 'op',
    Bancontact = 'bancontact',
    Sofort = 'directpay',
    Eps = 'eps',
    Giropay = 'giropay',
    Ideal = 'ideal',
    P24 = 'p24',
    Multibanco = 'multibanco',
    PaysafeCard = 'paysafecard',
};

export const paymentMethodToLogoMap = new Map<PaymentMethod, string>([
    [PaymentMethod.OpenBanking, openBankingLogo],
    [PaymentMethod.Bancontact, bancontactLogo],
    [PaymentMethod.Sofort, sofortLogo],
    [PaymentMethod.Eps, epsLogo],
    [PaymentMethod.Giropay, giropayLogo],
    [PaymentMethod.Ideal, idealLogo],
    [PaymentMethod.P24, p24Logo],
    [PaymentMethod.Multibanco, multibancoLogo],
    [PaymentMethod.PaysafeCard, paysafeCardLogo],
]);

export const countryToDefaultCurrencyMap = new Map<string, string>([
    ['SE', 'SEK'],
    ['NO', 'NOK'],
    ['FI', 'EUR']
]);

export const enum ExternalSearchQueryParams {
    Iban = 'iban',
    AccessToken = 'access_token',
    TokenType = 'token_type',
    ExpiresIn = 'expires_in',
};
