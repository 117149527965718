import { PropsWithChildren, Suspense, memo } from "react";
import { Outlet } from "react-router-dom";
import Fallback from "ui/molecules/Fallback";

const Lazy = ({ children }: PropsWithChildren) => (
    <Suspense
        fallback={<Fallback />}
    >
        {children}
    </Suspense>
);

Lazy.defaultProps = {
    children: <Outlet />
};

export default memo(Lazy);
