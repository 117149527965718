import { FC, PropsWithChildren, ReactNode, memo } from "react";
import type { Params } from "react-router-dom";
import { useParams } from "react-router-dom";
import NotFoundPage from 'ui/organizms/NotFound';

type Props = {
    readonly isParamValid: (
        param: Readonly<Params<string>>
    ) => boolean;
    readonly fallbackSlot?: ReactNode;
};

const ParamValidator: FC<PropsWithChildren<Props>> = ({
    children,
    isParamValid,
    fallbackSlot
}) => {
    const param = useParams();

    return (
        <>
            {isParamValid(param)
                ? children
                : fallbackSlot}
        </>
    );
};

ParamValidator.defaultProps = {
    fallbackSlot: (
        <NotFoundPage />
    )
};

export default memo(ParamValidator);
