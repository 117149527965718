import type { ButtonProps } from "@mui/material";

const useForm = () => ({
    inputSx: {
        width: '100%',
        mt: 2,
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    labelGroupSx: {
        mt: 4
    },
    submitProps: {
        sx: {
            mt: 5,
            mx: 'auto',
            display: 'flex'
        },
        type: 'submit' as ButtonProps['type'],
        variant: 'contained' as ButtonProps['variant'],
        disableElevation: true
    }
});

export default useForm;
