import type { ID } from "interface/entity";
import route from "util/route";

export const QUERY_PARAMETER = 'token';

export const enum RouteTypes {
    Login = 'login',
    Register = 'register',
    User = 'me',
    UpdateUser = 'users',
    Logout = 'logout',
    ResendEmail = 'resend-email',
    ForgotPassword = 'user/recover',
    ResetPassword = 'user/new-password'
};

export const AccountApiRoutes = {
    PostLogin: route(RouteTypes.Login),
    PostRegister: route(RouteTypes.Register),
    GetUser: route(RouteTypes.User),
    PutUser: (id: ID) => route(RouteTypes.UpdateUser, `${id}`),
    PostLogout: route(RouteTypes.Logout),
    GetResendEmail: (id: ID) => route(RouteTypes.UpdateUser, `${id}`, RouteTypes.ResendEmail),
    PostForgotPassword: route(RouteTypes.ForgotPassword),
    PostResetPassword: route(RouteTypes.ResetPassword)
};
