import type { CookieAttributes } from "js-cookie";

export const TOKEN_COOKIE_NAME = 'jwt';
export const REFRESH_TOKEN_THRESHOLD_DIVIDER = 2;
export const REFRESH_TOKEN_PATH = 'refresh';
export const COOKIE_DEFAULT_ATTRIBUTES: CookieAttributes = {
    sameSite: 'lax',
    path: '/',
    secure: process.env.NODE_ENV !== 'development'
};

