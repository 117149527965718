import Routes from "config/routes/web";
import { createBrowserRouter } from "react-router-dom";
import route, { param } from "util/route";
import TransactionStepper from "ui/layouts/TransactionStepper";
import { QUERY_PARAMETER } from "feature/transaction/consts";
import { loader as paymentMethodsLoader } from "ui/screens/Transaction/payment-methods.loader";
import { loader as topupStatusLoader } from "ui/screens/Result/topup-status.loader";
import Fallback from "ui/molecules/Fallback";
import TransactionResult from "ui/layouts/TransactionResult";
import LegalLayout from "ui/layouts/Legal";
import BasicLight from "ui/layouts/BasicLight";
import { Component as LegalScreen } from "ui/screens/Legal";
import { Translations } from "config/i18n";
import ResultScreen from "ui/screens/Result";
import SendMoney from "ui/screens/SendMoney";
import { TransferMoneyStep } from "feature/transaction/types";

const SendMoneyElement = (
    <SendMoney
        redirect={route(
            Routes.TransferMoney,
            TransferMoneyStep.First
        )}
    />
);

const legalRouteObject = {
    element: (
        <LegalLayout />
    )
};

export default createBrowserRouter([
    {
        ...legalRouteObject,
        path: route(Routes.Terms),
        children: [
            {
                index: true,
                element: (
                    <LegalScreen
                        translation={Translations.Terms}
                    />
                )
            }
        ]
    },
    {
        ...legalRouteObject,
        path: route(Routes.Privacy),
        children: [
            {
                index: true,
                element: (
                    <LegalScreen
                        translation={Translations.Privacy}
                    />
                )
            }
        ]
    },
    {
        path: route(Routes.SendMoney),
        loader: paymentMethodsLoader,
        element: (
            <TransactionStepper />
        ),
        children: [
            {
                index: true,
                element: SendMoneyElement
            },
            {
                path: param(QUERY_PARAMETER),
                loader: paymentMethodsLoader,
                element: SendMoneyElement
            }
        ]
    },
    {
        path: route(Routes.TransactionStatus),
        element: (
            <TransactionResult />
        ),
        children: [
            {
                index: true,
                Component: Fallback,
                loader: topupStatusLoader
            },
            {
                path: param(QUERY_PARAMETER),
                element: (
                    <ResultScreen />
                )
            }
        ]
    },
    {
        path: "*",
        element: (
            <BasicLight
                logoutButtonSlot={<></>}
                contactUsButtonSlot={<></>}
            />
        ),
        children: [
            {
                path: "*",
                lazy: () => import("ui/screens/NotFound")
            }
        ]
    }
]);
