import { memo, ReactNode } from "react";
import Box, { BoxProps } from "@mui/material/Box";

type Props = BoxProps & {
    readonly labelSlot: ReactNode;
    readonly actionSlot: ReactNode;
};

const AdditionalSupport = ({ labelSlot, actionSlot, ...boxProps }: Props) => (
    <Box
        sx={{
            mt: 4,
            ...(boxProps.sx ?? {})
        }}
        {...boxProps}
    >
        <Box
            component='span'
            sx={{
                color: 'var(--macro-input-placeholder-color)'
            }}
        >
            {labelSlot}
        </Box>
        &nbsp;
        {actionSlot}
    </Box>
);

export default memo(AdditionalSupport);
