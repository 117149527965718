import type { Actions, State } from "./types";

export const selectStatus = (state: State) => state.status;

export const selectAccount = (state: State) => state.account;

export const selectLogin = (state: Actions) => state.login;

export const selectRegister = (state: Actions) => state.register;

export const selectResetPassword = (state: Actions) => state.resetPassword;

export const selectUpdateAccount = (state: Actions) => state.updateAccount;

export const selectLogout = (state: Actions) => state.logout;
