import { memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Routes from "config/routes/web";
import route from "util/route";
import logoLight from "asset/images/logo-light.svg";
import logoDark from "asset/images/logo-dark.svg";

export type Props = BoxProps & {
    readonly type?: 'light' | 'dark';
};

const Logo = ({ component: Component, sx, type = 'light', ...restProps }: Props) => {
    const InnerComponent = (
        <Box
            component='img'
            sx={{
                xs: {
                    width: '130px',
                    height: '34px'
                },
                md: {
                    width: '170px',
                    height: '74px'
                },
                ...sx
            }}
            src={type === 'light'
                ? logoLight
                : logoDark}
            {...restProps}
        />
    );

    if (!Component) {
        return InnerComponent;
    }

    return (
        <Component to={route(Routes.Home)}>
            {InnerComponent}
        </Component>
    );
};

export default memo(Logo);
