const enum Routes {
    Home = '',
    TransferMoney = 'transfer-money',
    TransactionStatus = 'transaction-status',
    Terms = 'terms',
    Privacy = 'privacy',
    Signup = 'signup',
    Dashboard = 'dashboard',
    SendMoney = 'send-money',
    Login = 'login',
    ConfirmEmail = 'confirm-email',
    Iban = 'iban',
    ForgotPassword = 'forgot-password',
    ResetPassword = 'change-password'
};

export default Routes;
