import { useAuth } from "feature/account/hooks";
import { memo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";

type Props = ReturnType<typeof useAuth>;

const AuthAction = ({ onLogout, isAuthenticated }: Props) => {
    const { t } = useTranslation();

    const [isLogoutActive, setLoagoutActive] = useState(false);

    const handleLogout = () => {
        setLoagoutActive(true);
        onLogout();
    };

    const getActionContent = () =>
        isAuthenticated
            ? 'log-out'
            : 'log-in';

    return (
        <LoadingButton
            variant='outlined'
            disableElevation
            loading={isLogoutActive}
            onClick={handleLogout}
            sx={{
                height: "36px",
                width: "91px",
                borderRadius: '6px',
                p: '8px 16px',
                color: 'var(--macro-input-placeholder-color)',
                textTransform: 'capitalize',
                ':hover': {
                    borderColor: "var(--macro-grey-4)"
                },
                display: {
                    xs: "flex",
                    md: 'flex'
                }
            }}
        >
            {t(getActionContent())}
        </LoadingButton>
    );
};

export default memo(AuthAction);
