import { QUERY_PARAMETER } from "feature/transaction/consts";
import { TransferMoneyStep } from "feature/transaction/types";
import { Fragment, memo } from "react";
import Stepper from "ui/layouts/Stepper";
import useTransactionsStore from "feature/send-money/useStore";
import route from "util/route";
import Routes from "config/routes/web";
import Responsive from "ui/layouts/Responsive";
import { Desktop } from "ui/molecules/Footer";

const TransactionStepper = () => (
    <Stepper
        isStepValid={params => {
            const step = params[QUERY_PARAMETER] as TransferMoneyStep;

            if (step === TransferMoneyStep.Second) {
                return useTransactionsStore.getState()[TransferMoneyStep.First].isValid;
            }

            return true;
        }}
        isInitialStep={params => Object.is(
            params[QUERY_PARAMETER],
            TransferMoneyStep.First
        )}
        back={navigate => navigate(
            route(
                Routes.SendMoney,
                TransferMoneyStep.First
            ),
            { replace: true }
        )}
        next={navigate => navigate(
            route(
                Routes.SendMoney,
                TransferMoneyStep.Second
            )
        )}
        layoutProps={{
            headerProps: {
                position: 'absolute',
                elevation: 0,
                color: 'transparent',
                logoutButtonSlot: <></>
            },
            containerProps: {
                sx: {
                    height: '100vh',
                    minHeight: 650,
                    maxWidth: 'initial !important',
                    placeContent: 'initial',
                    alignItems: 'flex-start',
                    pb: {
                        xs: 8.1,
                        md: 15
                    }
                }
            },
            logoType: "dark",
            footerSlot: (
                <Responsive
                    xs={Fragment}
                    md={Desktop}
                    componentProps={{
                        containerProps: {
                            sx: {
                                position: 'initial',
                            }
                        }
                    }}
                />
            )
        }}
    />
);

export default memo(TransactionStepper);
