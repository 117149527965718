import { Theme, useMediaQuery } from "@mui/material";
import { memo, Fragment } from "react";
import App, { type AppProps } from "ui/layouts/App";

const Basic = (props: AppProps) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <App
            footerSlot={isMobile
                ? <Fragment />
                : undefined}
            {...props}
        />
    );
};

export default memo(Basic);
