import { getTopupStatus } from "feature/transaction/api";
import { STATUS_QUERY_PARAMETER } from "feature/transaction/consts";
import { TransactionStatus, TransactionStatusResponse } from "feature/transaction/types";
import { redirect } from "react-router-dom";
import route, { getSearchParam } from "util/route";
import Routes from "config/routes/web";
import { toast } from "react-toastify";

export const loader = async () => {
    try {
        // const token = setTokenFromSearchQueryParams();

        // console.log('topup-status.loader.ts: 11 => ', token);
        // if (!token) {
        //     throw new Error("Token is not found");
        // }

        const transactionUuid: string | undefined = getSearchParam({
            searchQueryName: STATUS_QUERY_PARAMETER
        });

        if (!transactionUuid) {
            throw new Error("Transaction uuid is not found");
        }

        // const jwtPayload = jwtDecode<JwtPayload>(token);

        // if (!jwtPayload.sub) {
        //     throw new Error("User id is not found");
        // }

        const { data: topupStatus } = await getTopupStatus(transactionUuid);
            console.log( 'topupStatus => ',topupStatus)
        switch (Number(topupStatus.data.status)) {
            case TransactionStatusResponse.SUCCEEDED: {
                return redirect(TransactionStatus.Suceeded);
            }
            case TransactionStatusResponse.PENDING: {
                return redirect(TransactionStatus.Pending);
            }
            default: {
                return redirect(TransactionStatus.Failed);
            }
        }
    }catch (error) {
        toast.error((error as Error).message);

        return redirect(route(Routes.Login));
    }
};
