import { memo, useCallback } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { QUERY_PARAMETER } from "feature/transaction/consts";
import { FirstStepState, SendMoneyStep } from "feature/send-money/types";
import usePaymentMethodStore from "feature/payment-method/useStore";
import { selectPaymentMethodsMapping } from "feature/payment-method/selectors";
import useSendMoneyStore from "feature/send-money/useStore";
import { selectStepFormData } from "feature/send-money/selectors";
import { useTranslation } from "react-i18next";
import { Translations } from "config/i18n";
import ReceivingIban from "ui/molecules/ReceivingIban/ReceivingIban";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PaymentMethodSelector from "ui/molecules/PaymentMethodSelector";
import { PaymentMethod } from "config/business";
import { FormFields, TransferMoneyStep } from "feature/transaction/types";
import { submitProps } from "config/ui";
import { ActionButton } from "ui/atoms/ActionButton";
import AdditionalSupport from "ui/molecules/AdditionalSupport";
import { mailto } from "util/support";
import useStore from "feature/account/useStore";
import { selectAccount } from "feature/account/selectors";
import useSendMoney from "ui/organizms/SendMoney/useSendMoney";
import { validator, useFirstStepSchema } from "ui/forms/TransferMoney/useSchema";
import useTransferMoneyForm from "ui/forms/TransferMoney/useForm";

const Mobile = () => {
    const account = useStore(selectAccount)!;
    const { t } = useTranslation([
        Translations.SendMoney,
        Translations.TransferMoney,
        Translations.Common
    ]);

    const param = useParams();

    const step = param[QUERY_PARAMETER] as SendMoneyStep;

    const { inputSx } = useTransferMoneyForm();

    const paymentMethodsMapping = usePaymentMethodStore(selectPaymentMethodsMapping);
    const stepFormData = useSendMoneyStore(selectStepFormData(step));

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: {
            isValid
        }
    } = useForm({
        resolver: yupResolver(useFirstStepSchema({
            translations: [
                Translations.TransferMoney,
                Translations.Common
            ]
        })),
        defaultValues: stepFormData as FirstStepState,
        mode: 'onBlur'
    });

    const paymentMethodValue = watch(FormFields.PaymentMethod);
    const onPaymentMethodChange = useCallback((paymentMethod: PaymentMethod) => {
        setValue(FormFields.PaymentMethod, paymentMethod, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
    }, [setValue]);

    const { onSubmit } = useSendMoney();

    return (
        <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
                 display: 'grid',
                // flexDirection: 'column',
                // justifyContent: 'space-between',
                px: '16px',
                pb: '43px',
                maxWidth:380
            }}
        >
            <Box>
                {/* <Box
                    sx={{
                        fontSize: 20,
                        fontWeight: 500,
                        color: 'primary.main',
                        textAlign: 'center',
                        mb: '33px'
                    }}
                >
                    {t(`${Translations.TransferMoney}:${TransferMoneyStep.First}.form.heading1`)}
                </Box> */}
                <Box
                    sx={{
                        display: 'grid',
                        placeContent: 'center',
                        mb: '30px'
                    }}
                >
                    <Box
                        sx={{
                            color: 'var(--macro-blue-light)',
                            textAlign: 'start',
                            mt: '24px',
                            fontSize: 19,
                            fontWeight: 500,
                            // maxWidth: 324
                        }}
                    >
                        {t(`${Translations.SendMoney}:${SendMoneyStep.First}.subtitle`)}
                    </Box>
                    <Controller
                        control={control}
                        name={FormFields.FirstName}
                        rules={validator[FormFields.FirstName]}
                        render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                                {...field}
                                sx={inputSx}
                                error={invalid}
                                helperText={error?.message}
                                inputProps={{
                                    maxLength: validator[FormFields.FirstName].maxLength
                                }}
                                label={t(`${Translations.TransferMoney}:${TransferMoneyStep.First}.form.formElements.${FormFields.FirstName}.label`)}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={FormFields.LastName}
                        rules={validator[FormFields.LastName]}
                        render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                                {...field}
                                sx={inputSx}
                                error={invalid}
                                helperText={error?.message}
                                inputProps={{
                                    maxLength: validator[FormFields.LastName].maxLength
                                }}
                                label={t(`${Translations.TransferMoney}:${TransferMoneyStep.First}.form.formElements.${FormFields.LastName}.label`)}
                            />
                        )}
                    />
                </Box>
                <PaymentMethodSelector
                    sx={{
                        mx: 'auto',
                        width: 'min-content'
                    }}
                    value={paymentMethodValue as PaymentMethod}
                    onChange={onPaymentMethodChange}
                    model={paymentMethodsMapping}
                />
            </Box>
            <ReceivingIban
                sx={{
                    mt: '20px',
                    alignItems: 'flex-end'
                }}
                labelSlot={t(`${Translations.SendMoney}:common.iban`)}
                ibanSlot={account.iban}
            />
            <Box>
                <ActionButton
                id="transfer-money-first-step"
                    {...submitProps}
                    sx={{
                        ...submitProps.sx,
                        width: '100%',
                        maxWidth: '100%'
                    }}
                    disabled={!isValid}
                >
                    {t(`${SendMoneyStep.First}.form.action`)}
                </ActionButton>
                <AdditionalSupport
                    sx={{
                        textAlign: 'center',
                        mt: '34px',
                        fontSize: 'var(--macro-font-size-regular)'
                    }}
                    labelSlot={t(`${Translations.Common}:support.issues`)}
                    actionSlot={(
                        <Box
                            sx={{
                                color: 'primary.main',
                                fontWeight: 700,
                            }}
                            component='a'
                            href={mailto()}
                        >
                            {t(`${Translations.Common}:support.link`)}
                        </Box>
                    )}
                />
            </Box>

        </Box>
    );
};

export default memo(Mobile);
