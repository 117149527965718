import { create } from "zustand";
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import state from "./state";
import type { Repository } from "interface/store";
import type { Actions, State } from "./types";
import { fetchPaymentMethods, setPaymentMethods } from "./actions";

const useStore = create<Repository<State, Actions>>()(
    devtools(immer(set => ({
        ...state,
        fetchPaymentMethods: fetchPaymentMethods(set),
        setPaymentMethods: setPaymentMethods(set)
    })))
);

export default useStore;
