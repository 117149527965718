import { memo, MouseEvent } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import Box, { BoxProps } from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { PaymentMethod, paymentMethodToLogoMap } from "config/business";

type Props = {
    readonly value: PaymentMethod;
    readonly onChange: (value: PaymentMethod) => void;
    readonly model: Map<PaymentMethod, string>;
    readonly paymentMethodImageRepository?: Map<PaymentMethod, string>;
    readonly sx?: BoxProps['sx'];
    readonly ariaLabel?: string;
};

const PaymentMethodSelector = ({
    value,
    onChange,
    model,
    ariaLabel = 'payment method',
    paymentMethodImageRepository = paymentMethodToLogoMap,
    sx = {}
}: Props) => {
    const handleChange = (_: MouseEvent<HTMLElement>, value: PaymentMethod) => {
        onChange(value);
    };

    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleChange}
            aria-label={ariaLabel}
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 92px)',
                gap: 1.5,
                ...sx
            }}
        >
            {Array.from(model.entries()).map(([method, name]) => (
                <ToggleButton
                    id= "payment-method"
                    key={method}
                    value={method}
                    aria-label={name}
                    sx={{
                        border: 'none',
                        borderRadius: '8px !important',
                        bgcolor: 'common.white',
                        // boxShadow: 'inset 0px 0px 0px 1px var(--macro-input-border-color)',
                        transition: 'box-shadow 0.2s ease-in-out',
                        '&.Mui-selected': {
                            bgcolor: 'common.white',
                            boxShadow: theme => `inset 0px 0px 0px 1px ${theme.vars.palette.primary.main}`,
                            ':hover': {
                                bgcolor: 'common.white'
                            }
                        },
                        padding:0,
                        height: 55,
                        width: 88,
                        ':hover': {
                            bgcolor: 'common.white'
                        }
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '100%',
                        }}
                        component='img'
                        src={paymentMethodImageRepository.get(method)}
                    />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default memo(PaymentMethodSelector);
