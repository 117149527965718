import api from "infrastructure/axios";
import type { Account, AccountResponse, Credentials, LoginResponse, PasswordResetRequest, Signup } from "./types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { AccountApiRoutes } from "./consts";
import { registerAccountRequestAdapter } from "./helpers";
import type { ID } from "interface/entity";
import type { Response } from "interface/api";
import { FormFields } from "./types";

export const login = (data: Credentials, config?: AxiosRequestConfig) =>
    api.post<Credentials, AxiosResponse<LoginResponse>>(
        AccountApiRoutes.PostLogin,
        registerAccountRequestAdapter<Credentials>(data),
        config
    );

export const register = (data: Signup, config?: AxiosRequestConfig) =>
    api.post<Signup, AxiosResponse>(
        AccountApiRoutes.PostRegister,
        registerAccountRequestAdapter<Signup>(data),
        config
    );

export const fetchAccount = (config?: AxiosRequestConfig) =>
    api.get<undefined, AxiosResponse<AccountResponse>>(
        AccountApiRoutes.GetUser,
        config
    );

export const updateAccount = (data: Partial<Account>, config?: AxiosRequestConfig) =>
    api.put<Partial<Account>, AxiosResponse<AccountResponse>>(
        AccountApiRoutes.PutUser(data.id!),
        data,
        config
    );

export const logout = (config?: AxiosRequestConfig) =>
    api.post<undefined, AxiosResponse>(
        AccountApiRoutes.PostLogout,
        undefined,
        config
    );

export const resendEmailConfirmation = (id: ID, config?: AxiosRequestConfig) =>
    api.get<undefined, AxiosResponse<Response<null>>>(
        AccountApiRoutes.GetResendEmail(id),
        config
    );

export const forgotPassword = (data: Pick<Account, FormFields.Email>, config?: AxiosRequestConfig) =>
    api.post<Pick<Account, FormFields.Email>, AxiosResponse<Response<null>>>(
        AccountApiRoutes.PostForgotPassword,
        data,
        config
    );

export const resetPassword = (data: PasswordResetRequest, config?: AxiosRequestConfig) =>
    api.post<PasswordResetRequest, AxiosResponse<Response<null>>>(
        AccountApiRoutes.PostResetPassword,
        data,
        config
    );
