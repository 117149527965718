import type { Option } from "interface/entity";
import type { StepState } from "interface/stepper";

export const enum TransactionStatus {
    Suceeded = 'suceeded',
    Failed = 'failed',
    Pending = 'pending'
};

export const enum TransactionStatusResponse {
    PENDING = 0,
    SUCCEEDED = 1,
    FAILED = 2
};

export const enum TransferMoneyStep {
    First = '1',
    Second = '2'
};

export type Step = TransferMoneyStep.First | TransferMoneyStep.Second;

export const enum FormFields {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    PaymentMethod = 'paymentMethod',
    Country = 'country',
    Amount = 'amount',
    Currency = 'currency',
    Iban = 'iban'
};

export type FirstStepState = {
    readonly [FormFields.FirstName]: string;
    readonly [FormFields.LastName]: string;
    readonly [FormFields.Email]: string;
    readonly [FormFields.PaymentMethod]: string;
};

export type SecondStepState = {
    readonly [FormFields.Country]: Option | null;
    readonly [FormFields.Amount]: string;
    readonly [FormFields.Currency]: string;
    readonly [FormFields.Iban]: string;
};

export type StepRegistry = {
    readonly [TransferMoneyStep.First]: FirstStepState;
    readonly [TransferMoneyStep.Second]: SecondStepState;
};

export type State = {
    [P in Step]: StepState<StepRegistry[P]>;
};

export type Actions = {
    readonly submitStep: <TStep extends Step>(data: StepRegistry[TStep]) => Promise<TopupResult>;
};

export type Topup = {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly iban: string;
    readonly method: string;
    readonly currency: string;
    readonly amount: string;
    readonly countryCode: string;
};

export type TopupResult = {
    readonly redirectUrl: string;
};

export type TopupStatusResult = {
    readonly status: number | string;
};
