import { memo } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Fallback = () => (
    <Box
        sx={{
            display: 'grid',
            placeContent: 'center',
            height: '100vh'
        }}
    >
        <CircularProgress
            variant='indeterminate'
        />
    </Box>
);

export default memo(Fallback);
