import api from "infrastructure/axios";
import { TransactionApiRoutes } from "./consts";
import { PaginatedResourceResponse, Response } from "interface/api";
import type { Topup as Transaction } from "../send-money/types";
import type { Topup, TopupResult, TopupStatusResult } from "./types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { ID } from "interface/entity";
import { headers } from "config/api";
import { getAuthorizationHeader } from "util/token";
import { API_URL } from "config/general";
import route from "util/route";

export const processTopup = (data: Topup, config?: AxiosRequestConfig) =>
    api.post<Topup, AxiosResponse<Response<TopupResult>>>(
        TransactionApiRoutes.Post,
        data,
        config
    );

export const getTopupStatus = (topupUuid: string, config?: AxiosRequestConfig) =>
    api.get<undefined, AxiosResponse<Response<TopupStatusResult>>>(
        TransactionApiRoutes.Get(topupUuid),
        config
    );

export const getUserTopupStatus = (topupUuid: string, userId: string, config?: AxiosRequestConfig) =>
    api.get<undefined, AxiosResponse<Response<TopupStatusResult>>>(
        TransactionApiRoutes.GetUserTopUp(userId, topupUuid),
        config
    );

// export const fetchUserTopupStatus = async (topupUuid: string, userId: string) => {
//     const response = await fetch(
//         `${API_URL}${TransactionApiRoutes.GetUserTopUp(userId, topupUuid)}`, {
//             headers: {
//                 ...headers,
//                 ...getAuthorizationHeader()
//             }
//         }
//     );

//     const userTopUpStatus: Response<TopupStatusResult> = await response.json();

//     if (!userTopUpStatus.success) {
//         throw new Error(userTopUpStatus.message);
//     }

//     return userTopUpStatus;
// };

export const fetchTopups = (userId: ID, config?: AxiosRequestConfig) =>
    api.get<undefined, AxiosResponse<PaginatedResourceResponse<Transaction>>>(
        TransactionApiRoutes.GetUserTopUps(userId),
        config
    );
