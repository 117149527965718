import { memo } from "react";
import Box from "@mui/material/Box";
import { FirstStepState, FormFields } from "feature/transaction/types";
import { PaymentMethod } from "config/business";

type Props = Pick<FirstStepState, FormFields.PaymentMethod> & {
    readonly paymentMethodImageRepository: Map<PaymentMethod, string>;
}

const PaymentMethodAdornment = ({
    [FormFields.PaymentMethod]: paymentMethod,
    paymentMethodImageRepository
}: Props) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            // height: 41
        }}
    >
        <Box
            component="img"
            sx={{
                maxWidth: 80,
                maxHeight: 41,
                // height: '100%',
                // width: '100%'
            }}
            src={paymentMethodImageRepository.get(paymentMethod as PaymentMethod)}
            alt={paymentMethod}
        />
    </Box>
);

export default memo(PaymentMethodAdornment);
