import { memo, ChangeEvent } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import flagCdnServiceFactory from "lib/flag-cdn";
import type { AutocompleteType, ChangeValuePayload, CountryPickerProps } from "./types";
import { ORIGIN } from "config/flag-cdn";
import type { InputProps } from "@mui/material/Input";
import type { Option } from "interface/entity";

const CountryPicker = ({
    multiple,
    onChange,
    getFlagSrc,
    TextFieldProps = {},
    ...restProps
}: CountryPickerProps) => {
    const handleChange = (_: ChangeEvent<{}>, value: ChangeValuePayload<typeof multiple>) => {
        onChange(value);
    };

    const isOptionEqualToValue = (opt: Option, val: Option) => {
        if (!val) {
            return true;
        }

        return opt.value === val.value;
    };

    return (
        <Autocomplete
            getOptionLabel={option => (option as Option).label ?? ''}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, { value, label }) => (
                <MenuItem
                    {...props}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr',
                        columnGap: 1
                    }}
                >
                    <Box
                        component='img'
                        src={getFlagSrc?.(value)}
                        alt={label}
                        width={25}
                    />
                    <Box
                        component='span'
                    >
                        {label}
                    </Box>
                </MenuItem>
            )}
            popupIcon={<KeyboardArrowDownIcon />}
            {...restProps}
            multiple={multiple}
            onChange={handleChange as AutocompleteType['onChange']}
            renderInput={params => (
                <TextField
                    {...params}
                    {...TextFieldProps}
                    sx={{
                        '& .MuiInputBase-root': {
                            pt: 0
                        },
                        ...(TextFieldProps.sx ?? {})
                    }}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        ...(TextFieldProps.InputProps ?? {})
                    } as Partial<InputProps>}
                />
            )}
        />
    );
};

CountryPicker.defaultProps = {
    ...flagCdnServiceFactory({
        origin: ORIGIN
    })
};

export default memo(CountryPicker);
