import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "config/i18n";
import type { Theme } from "@mui/material";
import { ActionButton } from "ui/atoms/ActionButton";
import { useParams } from "react-router-dom";
import { TransactionStatus } from "feature/transaction/types";
import { Palette } from "config/ui";
import { QUERY_PARAMETER } from "feature/transaction/consts";
import AdditionalSupport from "ui/molecules/AdditionalSupport";
import { mailto } from "util/support";
import { EXTERNAL_URL } from "config/general";


const Result = () => {
    const param = useParams();
    const { t } = useTranslation(Translations.Result);

    const transactionStatus = param[QUERY_PARAMETER] as TransactionStatus;

    const getChipStyling = (transactionStatus: TransactionStatus) => ({
        [TransactionStatus.Suceeded]: {
            sx: {
                bgcolor: (theme: Theme) => theme.vars.palette.success.main,
                color: (theme: Theme) => theme.vars.palette.primary.main,
            }
        },
        [TransactionStatus.Pending]: {
            sx: {
                bgcolor: (theme: Theme) => theme.vars.palette.primary.main,
                color: (theme: Theme) => theme.vars.palette.common.white,
            }
        },
        [TransactionStatus.Failed]: {
            sx: {
                bgcolor: (theme: Theme) => theme.vars.palette.error.main,
                color: (theme: Theme) => theme.vars.palette.common.white,
            }
        }
    }[transactionStatus]);

    const getActionButton = (transactionStatus: TransactionStatus) => {
        const commonActionButtonProps = {
            fullWidth: true,
            sx: {
                mt: 2,
                py: 2.1,
                lineHeight: 'unset'
            },
            component: 'a' as const,
            href: EXTERNAL_URL
        };

        return ({
            [TransactionStatus.Suceeded]: (
                <ActionButton
                    {...commonActionButtonProps}
                    id="result-suceeded"
                    color={Palette.Success}
                >
                    {t(`status.${transactionStatus}.action`)}
                </ActionButton>
            ),
            [TransactionStatus.Pending]: (
                <ActionButton
                    {...commonActionButtonProps}
                    id="result-pending"
                    sx={{
                        ...commonActionButtonProps.sx,
                        bgcolor: (theme: Theme) => theme.vars.palette.common.white,
                    }}
                >
                    Go back to Narvi.com
                </ActionButton>
            ),
            [TransactionStatus.Failed]: (
                <ActionButton
                    {...commonActionButtonProps}
                    id='result-failed'
                    color={Palette.Primary}
                >
                    {t(`status.${transactionStatus}.action`)}
                </ActionButton>
            )
        }[transactionStatus]);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'space-between',
                    color: (theme: Theme) => theme.vars.palette.primary.main,
                }}
            >
                <Box
                    sx={{
                        fontWeight: 600,
                        fontSize: {
                            xs: 20,
                            md: 32,
                        },
                        mt: {
                            xs: '50px',
                            md: 0
                        }
                    }}
                >
                    {t('heading')}
                </Box>
                <Chip
                    id = 'chip-element'
                    label={t(`status.${transactionStatus}.label`)}
                    sx={{
                        ...getChipStyling(transactionStatus).sx,
                        mt: '60px',
                        fontWeight: 400,
                        fontSize: 18,
                        p: '12px 32px',
                        height: 'auto',
                        borderRadius: '100px'
                    }}
                />
                <Box
                    sx={{
                        maxWidth: {
                            xs: 286,
                            md: 394,
                        },
                        mt: '83px',
                        fontWeight: 600,
                    }}
                >
                    {t(`status.${transactionStatus}.heading1`)}
                </Box>
                <Box
                    sx={{
                        maxWidth: {
                            xs: 286,
                            md: 394,
                        },
                        mt: 2
                    }}
                >
                    {t(`status.${transactionStatus}.heading2`)}
                </Box>
                {transactionStatus !== TransactionStatus.Suceeded && (
                    <Box
                        sx={{
                            maxWidth: {
                                xs: 286,
                                md: 394,
                            },
                            mt: 2
                        }}
                    >
                        {t(`status.${transactionStatus}.heading3`)}
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {getActionButton(transactionStatus)}
                <AdditionalSupport
                    labelSlot={t(`${Translations.Common}:support.issues`)}
                    actionSlot={(
                        <Box
                            sx={{
                                color: (theme: Theme) => theme.vars.palette.primary.main,
                            }}
                            component='a'
                            href={mailto()}
                        >
                            {t(`${Translations.Common}:support.link`)}
                        </Box>
                    )}
                />
            </Box>
        </Box>
    );
};

export default memo(Result);
