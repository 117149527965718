import { PaymentMethod } from "config/business";
import { SendMoneyStep } from "feature/send-money/types";
import { getSecondStepDefaultValues, useSecondStepSchema, validator } from "ui/forms/SendMoney/useSchema";
import { useTranslation } from "react-i18next";
import { useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFields } from "feature/transaction/types";
import Box from "@mui/material/Box";
import { LoadingActionButton } from "ui/atoms/ActionButton";
import { Option } from "interface/entity";
import LabelledGroup from "ui/atoms/LabelledGroup";
import PaymentMethodCountrySelector from "ui/molecules/PaymentMethodCountrySelector";
import TextFieldSelect from "ui/atoms/TextFieldSelect";
import { memo, useState } from "react";
import { Translations } from "config/i18n";
import usePaymentMethodStore from "feature/payment-method/useStore";
import { selectPaymentMethods } from "feature/payment-method/selectors";
import { useDefaultPaymentMethodCurrency } from "feature/transaction/hooks";
import { getPaymentMethodCountryOptions, getPaymentMethodCurrencyOptions } from "feature/payment-method/helpers";
import useSendMoneyStore from "feature/send-money/useStore";
import { formInputSx, submitProps } from "config/ui";
import ReceivingIban from "ui/molecules/ReceivingIban";
import AdditionalSupport from "ui/molecules/AdditionalSupport";
import { mailto, parseNumber } from "util/support";
import useStore from "feature/account/useStore";
import { selectAccount } from "feature/account/selectors";
import useSendMoney from "ui/organizms/SendMoney/useSendMoney";
import { Link } from "react-router-dom";
import route from "util/route";
import Routes from "config/routes/web";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";


const Mobile = () => {
    const account = useStore(selectAccount)!;
    const { t } = useTranslation([
        Translations.SendMoney,
        Translations.Common
    ]);

    const schema = useSecondStepSchema({ translations: [Translations.SendMoney] });
    const [acceptTerms, setAcepptTerms] = useState(false)
    const paymentMethods = usePaymentMethodStore(selectPaymentMethods);
    const paymentMethod = useSendMoneyStore
        .getState()
    [SendMoneyStep.First]
        .data[FormFields.PaymentMethod] as PaymentMethod;

    const paymentMethodCountries = getPaymentMethodCountryOptions(paymentMethod, paymentMethods);
    const paymentMethodCurrencies = getPaymentMethodCurrencyOptions(paymentMethod, paymentMethods);

    const {
        getCountryDefaultCurrency,
        getCurrencyOptions,
        getDefaultValues
    } = useDefaultPaymentMethodCurrency({
        paymentMethodCountries,
        paymentMethodCurrencies,
        defaultValues: getSecondStepDefaultValues()
    });

    const { onSubmit, isTopupSuccessful } = useSendMoney();

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: {
            isValid,
            isLoading,
            isSubmitting
        }
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(),
        mode: 'onBlur'
    });

    const {
        field: {
            ref: coutryInputRef,
            ...restCountryInputFieldProps
        }, fieldState: countryFieldState } = useController({
            name: FormFields.Country,
            control,
            rules: validator[FormFields.Country]
        });

    const { field: {
        ref: amountInputRef,
        ...restAmountInputFieldProps
    }, fieldState: amountFieldState } = useController({
        name: FormFields.Amount,
        control,
        rules: validator[FormFields.Amount]
    });

    const onAcceptTerms = () => {
        setAcepptTerms(!acceptTerms)
    }

    const onCountryChange = (country: Option) => {
        const coutryDefaultCurrency = getCountryDefaultCurrency(country);

        if (coutryDefaultCurrency) {
            setValue(FormFields.Currency, coutryDefaultCurrency);
        }

        restCountryInputFieldProps.onChange(country);
    };

    return (
        <Box
            component="form"
            sx={{
                display: 'grid',
                px: '16px',
                pb: '43px',
                maxWidth: 400,
                width: '100%',
                mx: 'auto'
            }}
            onSubmit={handleSubmit(onSubmit)}
        >

            <Box
                sx={{
                    mt: "30px"
                }}
            >
                <Box
                    sx={{
                        fontSize: 24,
                        color: "primary.main",
                        textAlign: "center",
                    }}
                >
                    {t(`${Translations.SendMoney}:${SendMoneyStep.Second}.title`)}
                </Box>
                <Box
                    sx={{
                        color: "var(--macro-blue-light)",
                        textAlign: "center",
                        mt: "24px",
                        mb: "52px",
                        width: 305,
                        maxWidth: 324,
                    }}
                >
                    {paymentMethodCountries.length > 1 ? t(`${Translations.SendMoney}:${SendMoneyStep.Second}.multiplePaymentMethodSubtitle`) : t(`${Translations.SendMoney}:${SendMoneyStep.Second}.singlePaymentMethodSubtitle`)}
                </Box>
                <PaymentMethodCountrySelector
                    {...restCountryInputFieldProps}
                    onChange={coutry =>
                        !Array.isArray(coutry) &&
                        onCountryChange(coutry)
                    }
                    options={paymentMethodCountries}
                    TextFieldProps={{
                        InputProps: {
                            fullWidth: true,
                            placeholder: t(`${Translations.SendMoney}:${SendMoneyStep.Second}.form.formElements.${FormFields.Country}.placeholder`) as string,
                            sx: {
                                height: 48
                            }
                        },
                        inputRef: coutryInputRef,
                        error: countryFieldState.invalid,
                        helperText: countryFieldState.error?.message
                    }}
                    paymentMethod={paymentMethod}
                />
                <TextFieldSelect
                    TextFieldProps={{
                        ...restAmountInputFieldProps,
                        inputRef: amountInputRef,
                        label: t(`${Translations.SendMoney}:${SendMoneyStep.Second}.form.formElements.${FormFields.Amount}.placeholder`),
                        sx: formInputSx,
                        onChange: ({ target }) =>
                            restAmountInputFieldProps.onChange(
                                parseNumber(target.value)
                            ),
                        inputProps: {
                            maxLength: validator[FormFields.Amount].maxLength
                        },
                        error: amountFieldState.invalid,
                        helperText: amountFieldState.error?.message
                    }}
                    SelectProps={{
                        options: getCurrencyOptions(watch(FormFields.Country)),
                        value: watch(FormFields.Currency),
                        inputProps: {
                            id: FormFields.Currency
                        },
                        onChange: ({ target }) =>
                            setValue(FormFields.Currency, target.value)
                    }}
                />
            </Box>
            <ReceivingIban
                sx={{
                    mt: '20px',
                    alignItems: 'flex-end'
                }}
                labelSlot={t(`${Translations.SendMoney}:common.iban`)}
                ibanSlot={account.iban}
            />
            <FormControlLabel
                sx={{
                    width: 320,
                    marginTop: '25px'
                }}
                control={<Checkbox checked={acceptTerms} onChange={onAcceptTerms} name="terms&conditions" />}
                label={
                    <Typography
                        sx={{
                            fontSize: 13,
                            color: "var(--macro-text-blue-light)",
                        }}
                    >
                        By checking this box, you acknowledge that you have reviewed and accepted Narvi's
                        <Box
                            sx={{ display: 'flex' }}>
                            <Link to='https://narvi.com/documents/terms-of-use' target="_blank">
                                <Box
                                    sx={{
                                        fontSize: 13,
                                        color: "var(--terms-and-condition-color)",
                                        paddingRight: '10px',
                                        fontWeight: 500
                                    }}
                                >
                                    {t(`${Translations.Common}:terms`)}
                                </Box>

                            </Link>
                            and
                            <Link to='https://narvi.com/documents/privacy-policy' target="_blank">
                                <Box
                                    sx={{
                                        fontSize: 13,
                                        color: "var(--terms-and-condition-color)",
                                        paddingLeft: '10px',
                                        fontWeight: 500
                                    }}
                                >
                                    {t(`${Translations.Common}:privacy`)}
                                </Box>
                            </Link>
                        </Box>
                    </Typography>
                }
            />
            <Box>
                <LoadingActionButton
                    {...submitProps}
                    sx={{
                        ...submitProps.sx,
                        width: '100%',
                        maxWidth: '100%',
                    }}
                    disabled={!isValid || isTopupSuccessful || !acceptTerms}
                    loading={isLoading || isSubmitting}
                >
                    {t(`${Translations.SendMoney}:${SendMoneyStep.Second}.form.action`)}
                </LoadingActionButton>
                <AdditionalSupport
                    sx={{
                        textAlign: 'center',
                        mt: '34px',
                        fontSize: 'var(--macro-font-size-regular)'
                    }}
                    labelSlot={t(`${Translations.Common}:support.issues`)}
                    actionSlot={(
                        <Box
                            sx={{
                                color: 'primary.main',
                                fontWeight: 700,
                            }}
                            component='a'
                            href={mailto()}
                        >
                            {t(`${Translations.Common}:support.link`)}
                        </Box>
                    )}
                />
            </Box>
        </Box>
    );
};

export default memo(Mobile);
