export type Response<T> = {
    readonly success: boolean;
    readonly code: number;
    readonly locale: string;
    readonly message: string;
    readonly data: T;
};

export type PaginatedResource<T> = {
    readonly current_page: number;
    readonly from: number;
    readonly last_page: number;
    readonly per_page: number;
    readonly to: number;
    readonly total: number;
    readonly data: Array<T>;
};

export type ResourceMetadata = {
    readonly relations: Array<string>;
    readonly sortable: Array<string>;
    readonly filterable: Array<string>;
    readonly sums: Array<string> | null;
};

export type PaginatedResourceResponse<T> = Response<
    & PaginatedResource<T>
    & ResourceMetadata
>;

export const enum ApiStatus {
    Idle = 'idle',
    Pending = 'pending',
    Failed = 'failed',
    Succeeded = 'succeeded'
};
