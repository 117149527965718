import type { ButtonProps } from "@mui/material";

export const DRAWER_WIDTH = 240;

export const TOOLBAR_HEIGHT = {
    Mobile: 88,
    Desktop: 88
};

export const enum Direction {
    LTR = 'ltr',
    RTL = 'rtl',
};

export const enum Breakpoint {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl'
};

export const enum Palette {
    Primary = 'primary',
    Secondary = 'secondary',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Success = 'success'
}

export const formSx = {
    width: '100%',
};

export const formInputSx = {
    ...formSx,
    mt: 2
};

export const labelGroupSx = {
    mt: 5
};

export const submitProps = {
    sx: {
        mt: 5,
        mx: 'auto',
        display: 'flex'
    },
    type: 'submit' as ButtonProps['type'],
    variant: 'contained' as ButtonProps['variant'],
    disableElevation: true
}
