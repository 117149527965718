import { useCallback, useEffect, useRef } from "react";
import { getToken } from "util/token";
import useStore from "./useStore";
import { selectAccount } from "./selectors";

const isLoadingSymbol = Symbol('isLoading');

interface UseFetchAccount {
    (): void;
    [isLoadingSymbol]?: boolean;
}

export const useFetchAccount: UseFetchAccount = () => {
    useEffect(() => {
        if (useFetchAccount[isLoadingSymbol]) {
            return;
        }

        const token = getToken();
        const account = useStore.getState().account;

        if (token && !account) {
            useFetchAccount[isLoadingSymbol] = true;

            useStore.getState()
                .fetchAccount()
                .finally(() => {
                    useFetchAccount[isLoadingSymbol] = false;
                });
        }
    }, []);
};

export const useLogout = () =>
    useCallback(() => {
        useStore.getState()
            .logout()
            .finally(() => window.location.reload());
    }, []);

export const useRememberUserEmail = () => {
    const userEmailRef = useRef<string>();

    const setEmail = useCallback((email: string) => {
        userEmailRef.current = email;
    }, []);

    const getEmail = useCallback(() =>
        userEmailRef.current
        , []);

    const removeEmail = useCallback(() =>
        userEmailRef.current = undefined
        , []);

    return {
        setEmail,
        getEmail,
        removeEmail
    };
};

export const useAuth = () => {
    const account = useStore(selectAccount);
    const isAuthenticated = Boolean(account);

    const onLogout = useLogout();

    return {
        isAuthenticated,
        account,
        onLogout
    };
};
