import { QUERY_PARAMETER } from "feature/transaction/consts";
import { TransactionStatus } from "feature/transaction/types";
import ParamValidator from "ui/layouts/ParamValidator";
import Result from "ui/organizms/Result";

export const Component = () => (
    <ParamValidator
        isParamValid={param => [
            TransactionStatus.Suceeded,
            TransactionStatus.Failed,
            TransactionStatus.Pending
        ].includes(param[QUERY_PARAMETER] as TransactionStatus)}
    >
        <Result />
    </ParamValidator>
);

Component.displayName = "Result";

export default Component;
