import { Fragment, memo } from "react";
import Basic from "ui/layouts/Basic";
import Responsive from "ui/layouts/Responsive";
import { Desktop } from "ui/molecules/Footer";

const Legal = () => (
    <Basic
        headerProps={{
            position: 'fixed',
            elevation: 0,
            sx: {
                bgcolor: 'var(--macro-background-main)'
            },
            logoutButtonSlot: <></>
        }}
        containerProps={{
            sx: {
                minHeight: '100vh',
                placeContent: 'initial',
                alignItems: 'flex-start',
                pb: {
                    xs: 8.1,
                    md: 15
                }
            }
        }}
        logoType="dark"
        footerSlot={(
            <Responsive
                xs={Fragment}
                md={Desktop}
            />
        )}
    />
);

export default memo(Legal);
