import { ComponentType, LazyExoticComponent, memo, Fragment } from "react";
import useBreakpoint from "./useBreakpoint";
import { Breakpoint } from "config/ui";

type Props = {
    readonly [P in Breakpoint]?: LazyExoticComponent<ComponentType<any>> | ComponentType;
} & {
    readonly componentProps?: object;
};

const Responsive = (props: Props) => {
    const xsPayload = useBreakpoint(Breakpoint.XS);
    const smPayload = useBreakpoint(Breakpoint.SM);
    const mdPayload = useBreakpoint(Breakpoint.MD);
    const lgPayload = useBreakpoint(Breakpoint.LG);
    const xlPayload = useBreakpoint(Breakpoint.XL);

    const getComponent = () => {
        for (const { matches, breakpoint } of [
            xlPayload,
            lgPayload,
            mdPayload,
            smPayload,
            xsPayload
        ]) {
            if (matches && (breakpoint in props)) {
                return props[breakpoint] ?? Fragment;
            }
        }

        return Fragment;
    }

    const Component = getComponent();

    return (
        <Component {...(props.componentProps ?? {})} />
    );
};

export default memo(Responsive);
