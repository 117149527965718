import type { StateHandler } from "interface/store";
import type { PaymentMethod, State } from "./types";
import { fetchPaymentMethods as fetchPaymentMethodsRequest } from "./api";
import { ApiStatus, PaginatedResourceResponse } from "interface/api";

export const fetchPaymentMethods = (set: StateHandler<State>) =>
    async () => {
        set(state => {
            state.status = ApiStatus.Pending;
        });

        try {
            const { data } = await fetchPaymentMethodsRequest();

            set(state => {
                state.status = ApiStatus.Succeeded;
                state.paymentMethodsResponse = data;
            });

            return data;
        } catch (error) {
            set(state => {
                state.status = ApiStatus.Failed;
            });

            throw error;
        }
    };

export const setPaymentMethods = (set: StateHandler<State>) =>
    (paymentMethods: PaginatedResourceResponse<PaymentMethod>['data']) => {
        set(state => {
            state.paymentMethods= paymentMethods;
        });
    };
