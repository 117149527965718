import { PropsWithChildren, createContext, useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const StepperContext = createContext<() => void>(() => {});

export type StepperContextProviderProps = {
    readonly next: (navigate: NavigateFunction) => void;
};

const StepperContextProvider = ({ children, next }: PropsWithChildren<StepperContextProviderProps>) => {
    const navidate = useNavigate();

    const handleGoNextStep = useCallback(() => {
        next(navidate);
    }, [next, navidate]);

    return (
        <StepperContext.Provider
            value={handleGoNextStep}
        >
            {children}
        </StepperContext.Provider>
    );
};

export default StepperContextProvider;
