import { create } from "zustand";
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import type { Repository } from "./types";
import state from "./state";
import { toggleNavbar } from "./actions";

const useStore = create<Repository>()(
    devtools(immer(subscribeWithSelector(set => ({
        ...state,
        toggleNavbar: toggleNavbar(set)
    }))))
);

export default useStore;
