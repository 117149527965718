import { FirstStepState, FormFields } from "feature/transaction/types";
import CountryPicker, { CountryPickerProps } from "ui/atoms/CountryPicker";
import { memo } from "react";
import PaymentMethodAdornment from "./PaymentMethodAdornment";
import { PaymentMethod, paymentMethodToLogoMap } from "config/business";

type Props =
    & CountryPickerProps
    & Pick<FirstStepState, FormFields.PaymentMethod>
    & {
        readonly paymentMethodImageRepository?: Map<PaymentMethod, string>;
    };

const PaymentMethodCountrySelector = (props: Props) => {
    const {
        [FormFields.PaymentMethod]: paymentMethod,
        paymentMethodImageRepository = paymentMethodToLogoMap,
        ...countryPickerProps
    } = props;

    const isAutocompleteDisabled = () => props.disabled || (props.options.length === 1);

    const getCountryPickerPropsOverrides = () => {
        const isDisabled = isAutocompleteDisabled();
        if (isDisabled) {
            return {
                popupIcon: null,
                clearIcon: null,
                disabled: isDisabled
            };
        }

        return {};
    };

    return (
        <CountryPicker
            {...countryPickerProps}
            {...getCountryPickerPropsOverrides()}
            TextFieldProps={{
                ...(props.TextFieldProps ?? {}),
                sx: {
                    ...(props.TextFieldProps?.sx ?? {}),
                    pointerEvents: isAutocompleteDisabled()
                        ? 'none'
                        : undefined
                },
                InputProps: {
                    ...(props.TextFieldProps?.InputProps ?? {}),
                    startAdornment: (
                        <PaymentMethodAdornment
                            paymentMethod={paymentMethod}
                            paymentMethodImageRepository={paymentMethodImageRepository}
                        />
                    )
                }
            }}
        />
    );
};

export default memo(PaymentMethodCountrySelector);
