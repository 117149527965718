import { Outlet, Link } from "react-router-dom";
import { ReactNode } from "react";
import { useTranslation } from 'react-i18next';
import { Theme, useMediaQuery } from "@mui/material";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Header from 'ui/molecules/Header';
import Logo from 'ui/atoms/Logo';
import route from 'util/route';
import Routes from 'config/routes/web';
import { Translations } from 'config/i18n';
import SigninWidget from "ui/widgets/SigninWidget/SigninWidget";

export type Props = {
    readonly backButtonSlot?: ReactNode;
    readonly logoutButtonSlot?: ReactNode;
    readonly contactUsButtonSlot?: ReactNode;
};

const Component = ({ backButtonSlot, logoutButtonSlot, contactUsButtonSlot }: Props) => {
    const { t } = useTranslation(Translations.Common);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                bgcolor: 'var(--macro-background-main)',
                height: '100vh'
            }}
        >
            <Container
                sx={{
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr auto',
                    height: '100%'
                }}
            >
                <Header
                    position='static'
                    color='transparent'
                    elevation={0}
                    logoutButtonSlot={(
                        <SigninWidget>
                            {logoutButtonSlot}
                        </SigninWidget>
                    )}
                    contactUsButtonSlot = {contactUsButtonSlot}
                    backButtonSlot={(
                        <Box
                            sx={{
                                display: {
                                    xs: 'block',
                                    md: 'none'
                                },
                            }}
                        >
                            {backButtonSlot}
                        </Box>
                    )}
                >
                    <Box
                        sx={{
                            position: 'relative'
                        }}
                    >
                        <Logo type='dark' />
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'block'
                                },
                                position: 'absolute',
                                right: 0,
                                bottom: -90
                            }}
                        >
                            {backButtonSlot}
                        </Box>
                    </Box>
                </Header>
                <Outlet />
                {!isMobile && (
                    <Box
                        sx={{
                            px: '24px',
                            py: 4,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Stack
                            direction='row'
                            spacing={4}
                            divider={(
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        bgcolor: 'var(--macro-text-blue-light)'
                                    }}
                                />
                            )}
                        >
                            <Link to={route(Routes.Terms)}>
                                <Box
                                    sx={{
                                        fontSize: 13,
                                        color: 'var(--macro-text-blue-light)'
                                    }}
                                >
                                    {t(`${Translations.Common}:terms`)}
                                </Box>
                            </Link>
                            <Link to={route(Routes.Privacy)}>
                                <Box
                                    sx={{
                                        fontSize: 13,
                                        color: 'var(--macro-text-blue-light)'
                                    }}
                                >
                                    {t(`${Translations.Common}:privacy`)}
                                </Box>
                            </Link>
                        </Stack>
                        <Box
                            sx={{
                                fontSize: 13,
                                color: 'var(--macro-text-blue-light)'
                            }}
                        >
                            {t(`${Translations.Common}:copyright`, {
                                year: new Date().getFullYear()
                            })}
                        </Box>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default Component;
