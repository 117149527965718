import { LoaderFunctionArgs, json } from "react-router-dom";
import { fetchPaymentMethods } from "feature/payment-method/api";
import usePaymentMethodsStore from "feature/payment-method/useStore";

export const loader = async ({ request }: LoaderFunctionArgs) => {
    const { paymentMethods } = usePaymentMethodsStore.getState();
    if (paymentMethods) {
        return json(paymentMethods);
    }

    const data = await fetchPaymentMethods({
        signal: request.signal
    })
    .then(response => {
        const { data: paymentMethods } = response;

        usePaymentMethodsStore.setState({
            paymentMethods: paymentMethods.data
        });

        return response;
    })
    .catch(() => ({}));

    return json(data);
};
