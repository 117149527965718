import { ApiStatus, type Response } from "interface/api";
import type { Timestamps } from "interface/entity";
import type { Entity } from "interface/entity";

export const enum FormFields {
    FirstName = 'firstName',
    LastName = 'lastName',
    Phone = 'phone',
    Email = 'email',
    Password = 'password',
    ConfirmPassword = 'confirmPassword'
};

export type Credentials = {
    readonly [FormFields.Email]: string;
    readonly [FormFields.Password]: string;
};

export type LoginResponse = Response<{
    readonly access_token: string;
    readonly token_type: string;
    readonly expires_in: number;
}>;

export type Signup = Credentials & {
    readonly [FormFields.FirstName]: string;
    readonly [FormFields.LastName]: string;
    readonly [FormFields.Phone]?: string;
    readonly [FormFields.ConfirmPassword]: string;
};

export type Account = Entity & Timestamps & {
    readonly first_name: string;
    readonly last_name: string;
    readonly phone_number: string;
    readonly email: string;
    readonly iban: string | null;
    readonly email_verified_at: string | null;
};

export type AccountResponse = Response<Account>;

export type State = {
    status: ApiStatus;
    account: Account | null;
};

export type Actions = {
    readonly login: (credentials: Credentials) => Promise<Account>;
    readonly register: (signup: Signup) => Promise<Account>;
    readonly resetPassword: (data: PasswordResetRequest) => Promise<Response<null>>;
    readonly fetchAccount: () => Promise<Account>;
    readonly updateAccount: (account: Partial<Account>) => Promise<Account>;
    readonly logout: () => Promise<void>;
};

export type PasswordResetRequest = {
    readonly [FormFields.Email]: string;
    readonly [FormFields.Password]: string;
    readonly token: string;
};

export type JwtPayload = {
    readonly sub: string;
};
