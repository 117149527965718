import Routes from "config/routes/web";
import { SendMoneyStep } from "feature/send-money/types";
import { QUERY_PARAMETER } from "feature/transaction/consts";
import { Navigate } from "react-router-dom";
import ParamValidator from "ui/layouts/ParamValidator";
import SendMoney from "ui/organizms/SendMoney";
import route from "util/route";

type Props = {
    readonly redirect?: string;
};

export const Component = ({ redirect = route(Routes.Dashboard) }: Props) => (
    <ParamValidator
        isParamValid={params => [
            SendMoneyStep.First,
            SendMoneyStep.Second
        ].includes(params[QUERY_PARAMETER] as SendMoneyStep)}
        fallbackSlot={(
            <Navigate
                replace
                to={redirect}
            />
        )}
    >
        <SendMoney />
    </ParamValidator>
);

Component.displayName = "SendMoney";
