import { PaymentMethod } from "config/business";
import isoCoutryServiceFactory, { IsoCountryCode } from "lib/iso-country";
import type { PaymentMethod as PaymentMethodType } from "./types";
import { convertToOption } from "util/support";

export const getPaymentMethodsMapping = (paymentMethods: PaymentMethodType[]) =>
    paymentMethods.reduce((acc, paymentMethod) =>
        acc.set(paymentMethod.method as PaymentMethod, paymentMethod.name),
        new Map<PaymentMethod, string>()
    );

export const getPaymentMethodCurrencyOptions = (paymentMethod: PaymentMethod, paymentMethods: PaymentMethodType[]) =>
    getPaymentMethodSubjectOptions(
        paymentMethod,
        paymentMethods,
        paymentMethodEntity => paymentMethodEntity.forCurrencies
    );

export const getPaymentMethodCountryOptions = (paymentMethod: PaymentMethod, paymentMethods: PaymentMethodType[]) =>
    getPaymentMethodSubjectOptions(
        paymentMethod,
        paymentMethods,
        paymentMethodEntity => paymentMethodEntity.forCountries
    )
    .map(({ label, ...rest }) => ({
        ...rest,
        label: isoCoutryServiceFactory().getCoutryName(label as IsoCountryCode)
    }));

function getPaymentMethodSubjectOptions(
    paymentMethod: PaymentMethod,
    paymentMethods: PaymentMethodType[],
    getPaymentMethodSubject: (paymentMethodEntity: PaymentMethodType) => string
) {
    const paymentMethodEntity = paymentMethods.find(({ method }) => Object.is(method, paymentMethod));

    if (!paymentMethodEntity) {
        return [];
    }

    return convertToOption(getPaymentMethodSubject(paymentMethodEntity).split(','));
}
