import { FirstStepState, SecondStepState, SendMoneyStep } from "feature/send-money/types";
import type { FormProps, FormValidator } from "interface/form";
import { number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import { NUMBER_REGEX } from "config/validation";
import { FormFields } from "feature/transaction/types";

type FormData = FirstStepState & SecondStepState;

export const getFirstStepDefaultValues = () => ({
    [FormFields.PaymentMethod]: '',
    [FormFields.FirstName]: '',
    [FormFields.LastName]: ''
});

export const getSecondStepDefaultValues = () => ({
    [FormFields.Country]: null,
    [FormFields.Amount]: '',
    [FormFields.Currency]: ''
});

export const validator: Record<keyof FormData, FormValidator> = {
    [FormFields.FirstName]: {
        required: true,
        minLength: 2,
        maxLength: 50
    },
    [FormFields.LastName]: {
        required: true,
        minLength: 2,
        maxLength: 50
    },
    [FormFields.PaymentMethod]: {
        required: true
    },
    [FormFields.Country]: {
        required: true
    },
    [FormFields.Amount]: {
        required: true,
        min: 10,
        max: 1000,
        maxLength: 6,
        pattern: NUMBER_REGEX
    },
    [FormFields.Currency]: {
        required: true
    }
};

export const useFirstStepSchema = () =>
    object()
        .shape({
            [FormFields.PaymentMethod]: string()
                .required()
        });

export const useSecondStepSchema = ({ translations }: Pick<FormProps<unknown>, 'translations'>) => {
    const { t } = useTranslation(translations);
    const getCurrencyValue = () =>
        (document.getElementById(FormFields.Currency) as HTMLInputElement)
            ?.value;

    return object()
        .shape({
            [FormFields.Country]: object()
                .required(`${t(`${SendMoneyStep.Second}.form.formElements.${FormFields.Country}.validation.required`)}`),
            [FormFields.Amount]: number()
                .required(`${t(`${SendMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.required`)}`)
                .typeError(`${t(`${SendMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.required`)}`)
                .min(validator[FormFields.Amount].min!, () =>
                    `${t(`${SendMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.min`, {
                        value: validator[FormFields.Amount].min,
                        [FormFields.Currency]: getCurrencyValue()
                    })}`
                )
                .max(validator[FormFields.Amount].max!, () =>
                    `${t(`${SendMoneyStep.Second}.form.formElements.${FormFields.Amount}.validation.max`, {
                        value: validator[FormFields.Amount].max,
                        [FormFields.Currency]: getCurrencyValue()
                    })}`
                ),
            [FormFields.Currency]: string()
                .required()
        });
}
