import { memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container, { type ContainerProps } from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Routes from "config/routes/web";
import route from "util/route";

type Props = {
    readonly rootProps?: BoxProps;
    readonly containerProps?: ContainerProps;
};

const Desktop = ({ rootProps = {}, containerProps = {} }: Props) => {
    const { t } = useTranslation();

    return (
        <Box
            {...rootProps}
            sx={{
                color: 'var(--macro-text-blue-light)',
                position: 'relative',
                ...rootProps.sx
            }}
        >
            <Container
                {...containerProps}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    px: {
                        md: 6
                    },
                    py: 4,
                    display: 'flex',
                    justifyContent: 'space-between',
                    ...containerProps.sx
                }}
            >
                <Stack
                    direction='row'
                    spacing={4}
                    divider={(
                        <Divider
                            orientation="vertical"
                            sx={{
                                bgcolor: 'var(--macro-text-blue-light)'
                            }}
                        />
                    )}
                >
                    <Link to={route(Routes.Terms)}>
                        <Box
                            sx={{
                                fontSize: 13,
                                color: 'var(--macro-text-blue-light)'
                            }}
                        >
                            {t('terms')}
                        </Box>
                    </Link>
                    <Link to={route(Routes.Privacy)}>
                        <Box
                            sx={{
                                fontSize: 13,
                                color: 'var(--macro-text-blue-light)'
                            }}
                        >
                            {t('privacy')}
                        </Box>
                    </Link>
                </Stack>
                <Box
                    sx={{
                        fontSize: 13
                    }}
                >
                    {t('copyright', {
                        year: new Date().getFullYear()
                    })}
                </Box>
            </Container>
        </Box>
    );
};

export default memo(Desktop);
