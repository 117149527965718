import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { Locale, Translations } from "config/i18n";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        // lng: "fr", // if you're using a language detector, do not define the lng option
        fallbackLng: Locale.EN,
        defaultNS: Translations.Common,
        debug: process.env.NODE_ENV === 'development'
    });
