import { TopupResult, TransactionStatusResponse } from "feature/transaction/types";
import {
    FormFields,
    FirstStepState as TransactionFirstStep,
    SecondStepState as TransactionSecondStep,
} from "feature/transaction/types";
import type { PaginatedResourceResponse } from "interface/api";
import type { StepState } from "interface/stepper";

export const enum SendMoneyStep {
    First = '1',
    Second = '2'
};

export type Step = SendMoneyStep.First | SendMoneyStep.Second;

export type FirstStepState = Omit<TransactionFirstStep, FormFields.Email>;

export type SecondStepState = Pick<
    TransactionSecondStep,
    | FormFields.Country
    | FormFields.Amount
    | FormFields.Currency
>;

export type StepRegistry = {
    readonly [SendMoneyStep.First]: FirstStepState;
    readonly [SendMoneyStep.Second]: SecondStepState;
};


export type State = {
    [P in Step]: StepState<StepRegistry[P]>;
};

export type Actions = {
    readonly submitStep: <TStep extends Step>(data: StepRegistry[TStep]) => Promise<TopupResult>;
};

export type Topup = {
    readonly topUpId: string;
    readonly method: string;
    readonly currency: string;
    readonly amount: string;
    readonly status: TransactionStatusResponse;
    readonly createdAt: string;
};

export type ListTopUpsResponse = PaginatedResourceResponse<Topup>;
