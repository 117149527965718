import { create } from "zustand";
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import getState from "./state";
import type { Repository } from "interface/store";
import type { Actions, State } from "./types";
import { sign, fetchAccount, updateAccount, logout, resetPassword } from "./actions";
import { login, register } from "./api";

const useStore = create<Repository<State, Actions>>()(
    devtools(immer(set => ({
        ...getState(),
        login: sign(set, login),
        register: sign(set, register),
        resetPassword: resetPassword(set),
        fetchAccount: fetchAccount(set),
        updateAccount: updateAccount(set),
        logout: logout(set)
    })))
);

export default useStore;
