import axios, { AxiosResponse } from "axios";
import { headers } from "config/api";
import { API_URL } from "config/general";
import { resetStore } from "feature/account/helpers";
import { toast } from 'react-toastify';
import { isUnauthorized } from "util/support";
import {
    getAuthorizationHeader,
    getToken,
    refreshTokenIfExpired,
    removeToken
} from "util/token";

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers
});

axiosInstance.interceptors.request.use(async config => {
    // Do something before request is sent
    try {
        await refreshTokenIfExpired();
        const authToken = getToken();

        if (authToken) {
            config.headers['Authorization'] = getAuthorizationHeader().Authorization;
        }
    }catch {
        reset();
    }

    return config;
});

axiosInstance.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('axiosInstance.interceptors.response response => ', response);
    if (!response.data.success) {
        processFailedResponse(response);
        toast.error(response.data.message || `${response.data.code} - Ops Marius please look into that`);
    }
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    toast.error(error.message);
    console.log('axiosInstance.interceptors.response error => ', error);
    return Promise.reject(error);
});

export default axiosInstance;

function processFailedResponse(response: AxiosResponse) {
    if (isUnauthorized(response.data.code)) {
        reset();
    }
}

function reset() {
    removeToken();
    resetStore();
}
